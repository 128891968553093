import odoo_api from '../../odooAPI/backend_api_wrapper'
import moment from 'moment'
import promise from 'promise'

export const LogoutOdoo_SchedaCommessa = () => ({
    type: 'LOGOUT'
})

export const OnlyActiveProjects = () => ({ 
    type: 'ONLY_ACTIVE_PROJECTS'
})

export const AllProjects = () => ({ 
    type: 'ALL_PROJECTS'
})

export const UpdateSelectedProject = (project) =>({
    type: 'UPDATE_CURRENT_PROJECT',
    payload: project
})

export const SetSelectedFilter = (key, values) => ({
    type: 'SET_SEL_FILTER',
    payload: {key: key, values: values}
})

export const UpdateSelectedDateRange = (date) =>({
    type: 'UPDATE_CURRENT_DATE',
    payload: date
})

export const GenerateLastUpdatePM = (project_id,status) =>({
    type: 'GET_LAST_UPDATE',
    payload: odoo_api.get_pm_last_update(project_id, status)
})

export const SendProjectToBE = () =>({
    type: 'SENDED_PROJECT',
    payload: ''
})

export const AddFollowerProject = (prjid, userid) => (
    {
        type: 'ADD_FOLLOWER_PROJECT',
        payload: odoo_api.addfollowerproject(prjid, userid)
    }
)

export const SetUser = (user) => ({
    type: 'SET_USER',
    payload: user
})

export const SetAdmin = () => ({
    type: 'SET_ADMIN',
    payload: null
})

export const GetAllProjects = () => ({
        type: 'GET_ALL_PRJS',
        payload: odoo_api.getallprojects()
})

export const RemoveFollowerProject = (prjid, userid) => (
    {
        type: 'REMOVE_FOLLOWER_PROJECT',
        payload: odoo_api.removefollowerproject(prjid, userid)
    }
)

export const GetProjectsList = (emp_id, allprj) =>{
    if(emp_id === undefined){

        return(
            {
                type: 'GET_PROJECTS_LIST',
                payload: odoo_api.get_projects_list_admin(allprj)
            }
        )
    } else {
        return(
            {
                type: 'GET_PROJECTS_LIST',
                payload: odoo_api.get_projects_list_pm(emp_id, allprj)
            }
        )
    }
}

export const GetProjectInfo = (project_id, isactive, date) =>({
    type: 'GET_INFO_PROJECT',
    payload: odoo_api.get_all_project_info_maturato(project_id, isactive, date)
})

export const GetFollowersProject = (prjid) =>({
    type: 'GET_FOLLOWERS_PROJECT',
    payload: odoo_api.getfollowersproject(prjid)
})

export const ProjectReadyToRender = (minmaxdate_maturato, minmaxdate_prevsionato, colonne_maturato, colonne_previsionato, righe, previsionato_day, previsionato_expense) => ({
    type: 'PROJECT_READY_TO_RENDER',
    colonne_maturato: colonne_maturato,
    colonne_previsionato: colonne_previsionato,
    righe: righe,
    previsionato_day: previsionato_day,
    previsionato_expense: previsionato_expense,
    minmaxdate_maturato: minmaxdate_maturato,
    minmaxdate_prevsionato: minmaxdate_prevsionato
})

export const ResetSchedaCommessa = () => ({
    type: 'RESET_SCHEDA_COMMESSA',
})

export const ResetGetProjectInfo = () =>({
    type: 'GET_INFO_PROJECT_RESET'
})

export const HandleEditCells = (data) => ({
    type: 'HANDLE_EDIT_CELLS',
    edit_data: data
})

export const GetInfoEmpContracts = () =>({
    type: 'GET_INFO_EMPCONTRACTS',
    payload: odoo_api.getlistempcontracts()
})

export const AddEmpToSchedaCommessa = (keyid, empinfo) => ({
    type: 'ADDEMP_SCHEDACOMMESSA',
    keyid: keyid,
    empinfo: empinfo,
})

export const AddDeletePrevisionato = (ids, keyids) => ({
    type: 'DELETE_RECORD_PREVISIONATO',
    ids: ids,
    keyids: keyids
})

export const SetNewStartDate = (date) => ({
    type: 'SET_START_DATE',
    date: date,
})

export const SetNewEndDate = (date) => ({
    type: 'SET_END_DATE',
    date: date,
})

export const UpdateGenInfo = (part_geninfo) => ({
    type: 'UPDATE_GENINFO',
    part_geninfo: part_geninfo
})

export const UpdateAllPrevisionatoData = (idproject, start_date, end_date, delete_prev_info, update_prev_days, write_prev_days, update_prev_expense, write_prev_expense) => {

    var array_update_promise = []

    if (delete_prev_info.length > 0){
        delete_prev_info.map((id) => {
            var promise = odoo_api.deleteprevisionato(id)
            array_update_promise.push(promise)
        })
    }

    if (update_prev_days.count() > 0){
        update_prev_days.forEach((row) => {
            if(row.ignore != true){
                var promise = odoo_api.updateprevisionato(row.id, row)
                array_update_promise.push(promise)
            }
        })
    }

    if (write_prev_days.count() > 0){
        write_prev_days.forEach((row) => {
            var promise = odoo_api.insertprevisionato(row)
            array_update_promise.push(promise)
        })
    }

    if (update_prev_expense.count() > 0){
        update_prev_expense.forEach((row) => {
            if(row.ignore != true){
            var promise = odoo_api.updateprevisionato(row.id, row)
            array_update_promise.push(promise)
            }
        })
    }

    if (write_prev_expense.count() > 0){
        write_prev_expense.forEach((row) => {
            var promise = odoo_api.insertprevisionato(row)
            array_update_promise.push(promise)
        })
    }

    if (start_date != false){
        var promise = odoo_api.updatestartdateproject(idproject, start_date)
        array_update_promise.push(promise)
    }

    if (start_date != false){
        var promise = odoo_api.updateenddateproject(idproject, end_date)
        array_update_promise.push(promise)
    }

    return {
        type: 'UPDATE_ALL_PREVISIONATO_DATA',
        payload: Promise.all(array_update_promise)
    }
}

export const CreateXLS_SchedaCommessa = (scheda_commessa) => {


    // GenerateJSON Download XLS
    var a = ""

    // scheda_commessa.info_project.x_studio_tm
    var temp_json = {
        "Progetto": {
          "Nome": "",
          "Mesi": {
              "Maturati": [],
              "Previsionati": []
          },
          "Consulenti": []
        },
        "Economico": {
          "Business Case": 0
        },
        "Contabile": {
          "Task": false,
          "Fatture": [],
          "Totale Contrattuale": scheda_commessa.info_project.totale_piano_economico == null ? 0 : scheda_commessa.info_project.totale_piano_economico
        }
      }

    // Aggiungo Nome Progetto 
    temp_json.Progetto.Nome = scheda_commessa.selected_project.name

    //Imposto se è TimeMaterial
    temp_json.Contabile.Task = !scheda_commessa.selected_project.x_studio_tm

    // Aggiungo tutti i Mesi che ho a Disposizione
    scheda_commessa.colonne_maturato = scheda_commessa.colonne_maturato.sort((a,b) => new moment(a.key.replace('date_','')).format('YYYYMMDD') - new moment(b.key.replace('date_','')).format('YYYYMMDD'))
    scheda_commessa.colonne_maturato.map((mese) => {
        temp_json.Progetto.Mesi.Maturati.push(moment(mese.key.replace('date_','')).format('MM-YYYY'))
    })
    scheda_commessa.colonne_previsionato = scheda_commessa.colonne_previsionato.sort((a,b) => new moment(a.key.replace('date_','')).format('YYYYMMDD') - new moment(b.key.replace('date_','')).format('YYYYMMDD'))
    scheda_commessa.colonne_previsionato.map((mese) => {
        temp_json.Progetto.Mesi.Previsionati.push(moment(mese.key.replace('date_','')).format('MM-YYYY'))
    })


    // Aggiungo tutti i consulenti
    Object.keys(scheda_commessa.righe_scheda).map((key) => {

        if(key !== "somma_verticale"){

            var temp_cons_json = {
                "Consulente": "",
                "Livello": "",
                "Costi": {
                  "Costo STD": 0,
                },
                "Trasferta": {
                  "Maturato YTD": 0,
                  "Previsto a finire": 0
                },
                "Giorni": {
                  "Mesi": {}
                }
              }

            temp_cons_json.Consulente = scheda_commessa.righe_scheda[key].consulente
            temp_cons_json.Livello = scheda_commessa.righe_scheda[key].profilo
            temp_cons_json.Costi["Costo STD"] = parseFloat(scheda_commessa.righe_scheda[key].costo_profilo.replace(" €", ""))
            temp_cons_json.Trasferta["Maturato YTD"] = parseFloat(scheda_commessa.righe_scheda[key].costo_trasferta_maturato.replace(" €", ""))
            temp_cons_json.Trasferta["Previsto a finire"] = parseFloat(scheda_commessa.righe_scheda[key].costo_trasferta_previsto.replace(" €", ""))

            Object.keys(scheda_commessa.righe_scheda[key]).filter(value => value.includes('date_')).map((prop) => {
                temp_cons_json.Giorni.Mesi[moment(prop.replace('date_','')).format('MM-YYYY')] = parseFloat(scheda_commessa.righe_scheda[key][prop])
            })

            
            temp_json.Progetto.Consulenti.push({...temp_cons_json})

        }
        
    })

    // Aggiungo il BusinessCase
    temp_json.Economico["Business Case"] = parseFloat(scheda_commessa.selected_project.x_studio_business_case)

    // Aggiungo tutte le fatture
    scheda_commessa.info_project.piano_contabile_dafatturare.filter((row) => { return row.price_subtotal != 0}).map((fattura) => {

        var temp_fatt_json = {
            "Descrizione": "",
            "N° Fattura": "",
            "Ordine Lobra": "",
            "Ordine cliente": "",
            "Data fattura": "",
            "Data pagamento": "",
            "Importo fatturato": 0,
            "Valore": "",
            "Importo da fatturare": 0,
            "Incassato": ""
        }

        if(scheda_commessa.selected_project.x_studio_tm){

            // TimeMaterial
            temp_fatt_json.Descrizione = fattura.invoice_name
            temp_fatt_json["N° Fattura"] = fattura.label_invoice
            temp_fatt_json["Ordine Lobra"] = fattura.label_sale_order_id
            temp_fatt_json["Ordine cliente"] = fattura.x_studio_customer_order_nr
            temp_fatt_json["Data fattura"] = ""
            temp_fatt_json["Data pagamento"] = ""
            temp_fatt_json["Importo fatturato"] = ""
            temp_fatt_json["Valore"] = parseFloat((fattura.type_name == 'Credit Note' ? '-' : '') + fattura.product_uom_qty) // Ore effettuate
            temp_fatt_json["Importo da fatturare"] = fattura.price_unit * fattura.product_uom_qty
            temp_fatt_json["Incassato"] = fattura.invoice_payment_state != 'paid' ? 'N' : 'Y'
    
            temp_json.Contabile.Fatture.push({...temp_fatt_json})
            
        } else {

            // Task
            temp_fatt_json.Descrizione = fattura.name
            temp_fatt_json["N° Fattura"] = fattura.label_invoice
            temp_fatt_json["Ordine Lobra"] = fattura.label_sale_order_id
            temp_fatt_json["Ordine cliente"] = fattura.x_studio_customer_order_nr
            temp_fatt_json["Data fattura"] = fattura.invoice_status == 'invoiced' ? moment(fattura.date).format('DD-MM-YYYY') : ""
            temp_fatt_json["Data pagamento"] = fattura.invoice_status == 'invoiced' ? moment(fattura.invoice_date_due).format('DD-MM-YYYY') : ""
            temp_fatt_json["Importo fatturato"] = (fattura.type_name == 'Credit Note' ? '-' : '') + fattura.invoice_status == 'invoiced' ? fattura.amount_untaxed_journal : ""
            temp_fatt_json["Valore"] = moment(fattura.x_studio_next_invoicing_date).format('DD-MM-YYYY') // Data next invoice
            temp_fatt_json["Importo da fatturare"] = fattura.invoice_status != 'invoiced' ? fattura.price_subtotal : ""
            temp_fatt_json["Incassato"] = fattura.invoice_payment_state != 'paid' ? 'N' : 'Y'   
            temp_json.Contabile.Fatture.push({...temp_fatt_json})
    
        }


    })

    scheda_commessa.info_project.piano_contabile_fatturato.map((fattura) => {

        var temp_fatt_json = {
            "Descrizione": "",
            "N° Fattura": "",
            "Ordine Lobra": "",
            "Ordine cliente": "",
            "Data fattura": "",
            "Data pagamento": "",
            "Importo fatturato": 0,
            "Valore": "",
            "Importo da fatturare": 0,
            "Incassato": ""
        }

        if(scheda_commessa.selected_project.x_studio_tm){

            // TimeMaterial
            temp_fatt_json.Descrizione = fattura.invoice_name
            temp_fatt_json["N° Fattura"] = fattura.label_invoice
            temp_fatt_json["Ordine Lobra"] = fattura.invoice_origin
            temp_fatt_json["Ordine cliente"] = fattura.x_studio_customer_order_nr
            temp_fatt_json["Data fattura"] = fattura.state == 'posted' ? (moment(fattura.date_creation_journal).format('DD-MM-YYYY')) : ('')
            temp_fatt_json["Data pagamento"] = fattura.state == 'posted' ? (moment(fattura.invoice_date_due).format('DD-MM-YYYY')) : ('')
            temp_fatt_json["Importo fatturato"] = parseFloat((fattura.type_name == 'Credit Note' ? '-' : '') + (fattura.state == 'posted' ? fattura.amount_untaxed_journal : ('')))
            temp_fatt_json["Valore"] = parseFloat((fattura.type_name == 'Credit Note' ? '-' : '') + (fattura.state == 'posted' ? (fattura.quantity) : (fattura.qty_delivered)))
            temp_fatt_json["Importo da fatturare"] = ""
            temp_fatt_json["Incassato"] = fattura.invoice_payment_state != 'paid' ? 'N' : 'Y'
    
            temp_json.Contabile.Fatture.push({...temp_fatt_json})
            
        } else {

            // Task
            temp_fatt_json.Descrizione = fattura.invoice_name
            temp_fatt_json["N° Fattura"] =  fattura.label_invoice
            temp_fatt_json["Ordine Lobra"] = fattura.invoice_origin
            temp_fatt_json["Ordine cliente"] = fattura.x_studio_customer_order_nr
            temp_fatt_json["Data fattura"] = moment(fattura.invoice_date).format('DD-MM-YYYY')
            temp_fatt_json["Data pagamento"] = moment(fattura.invoice_date_due).format('DD-MM-YYYY')
            temp_fatt_json["Importo fatturato"] = (fattura.type_name == 'Credit Note' ? '-' : '') + fattura.state == 'posted' ? fattura.amount_untaxed_journal : ('')
            temp_fatt_json["Valore"] = (moment(fattura.x_studio_next_invoicing_date).format('DD-MM-YYYY'))
            temp_fatt_json["Importo da fatturare"] = fattura.state != 'posted' ? fattura.price_unit : ''
            temp_fatt_json["Incassato"] = fattura.invoice_payment_state != 'paid' ? 'N' : 'Y'
    
            temp_json.Contabile.Fatture.push({...temp_fatt_json})
    
        }
    })

    return({
        type: 'CREATE_XLS_SCHEDA',
        payload: odoo_api.create_xls_schedacommessa(temp_json)
    })
}

export const XLSDownloaded = (json) => ({
    type: 'XLS_DOWNLOADED',
    payload: ''
})
