import moment from 'moment'
import mergeAdvanced from "object-merge-advanced";

const INITIAL_STATE = {
    info_login: {
        is_loggingin: false,
        is_loggedin: false,
        error: false,
        error_msg: ''
    }
}

const currentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'TRYDO_LOGIN_ODOO':
            return state
        case 'TRYDO_LOGIN_ODOO_PENDING':
            return {
                ...state,
                info_login: {
                    ...state.info_login,
                    is_loggingin: true,
                }
                
            }
        case 'TRYDO_LOGIN_ODOO_FULFILLED':
            return {
                ...state,
                info_login: action.payload,
                is_loggingin: false,
            }
        case 'LOGOUT_ODOO':
            return {
                ...state,
                info_login: {
                    ...state.info_login,
                    is_loggedin: false,
                    is_loggingin: false,
                }
            }
        
        default:
            return state
    }
}

export default currentReducer