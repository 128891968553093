import React, { useState, useEffect } from 'react'
import moment from 'moment'
import  {Popconfirm, Col, Row, Tooltip, Avatar, Card, Skeleton, Switch, Select, Collapse, TreeSelect, Space} from 'antd';
import * as jquery from 'jquery';
import {connect} from 'react-redux'
import {IconButton, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import { Icon } from 'semantic-ui-react'
import "moment/locale/it"

const gridStyle = {
    margin: '5px',
    width: '19%',
    textAlign: 'center',
  };

 const CardProject = (props) => {
    return (
  <Card.Grid
          style={gridStyle}
        >
          <Skeleton loading={false} avatar active>
            <Card.Meta
              avatar={<Avatar src="https://thumbs.dreamstime.com/b/icon-blueprints-architect-blueprints-148485317.jpg" />}
              title={props.project.name}
              description={props.project.user_id[1]}
              onClick={() => {
                props.update_sel_prj(props.project)
                props.sendprjbe()
            }
            }
            />
          </Skeleton>
        </Card.Grid>
    )
  }

  const PushInfoSelectedFilters = (data, chiave) => {

  }

  const GenUniqueFilterData = (prjlist, value) => {
    // Filtro Progetto - Aperto/Chiuso
    if(value == "x_studio_close_project"){
        return [
            {
              title: 'Aperto',
              value: false,
              key: 'false',
            },
            {
              title: 'Chiuso',
              value: true,
              key: 'true',
            },
          ]
    }
    // Filtro Company
    if(value == "company_id"){
        var unique = prjlist.map(item => item.company_id[1]).filter((value, index, self) => self.indexOf(value) === index).map(data => ({title: data, value: data, key: data}))
        return unique
    }

    // PM
    if(value == "user_id"){
        var unique = prjlist.map(item => item.user_id[1]).filter((value, index, self) => self.indexOf(value) === index).map(data => ({title: data, value: data, key: data}))
        return unique
    }

    // Country
    if(value == "partner_id"){
        var unique = prjlist.map(item => item.partner_id[1]).filter((value, index, self) => self.indexOf(value) === index).map(data => ({title: data, value: data, key: data}))
        return unique
    }
  }

  const FiltroTag = (props) => {

    return(
        <Col flex="25%">
            <h5>{props.label}</h5>
        <TreeSelect
            treeData={GenUniqueFilterData(props.prjlist, props.chiave)}
            treeCheckable={true}
            showCheckedStrategy={'SHOW_PARENT'}
            defaultValue={props.filters[props.chiave]}
            onChange={(data) => {props.act_filter(props.chiave, data)}}
            style={{width: '100%'}}
        />
        </Col>
    )
}

const FiltroNome = (props) => {

    return(
        <Col flex="100%">
            <h5>{props.label}</h5>
        <Select 
            mode="tags" 
            style={{ width: '100%' }} 
            placeholder="Nome del Progetto" 
            onChange={(data) => {props.act_filter(props.chiave, data)}}
            defaultValue={props.filters[props.chiave]}>
        </Select>
        </Col>
    )
}



const RicercaProgetti = (props) => {
    useEffect(() => {
        props.GetAllProjects()
    }, []);
    var projects = props.schedacommessa.projects_list
    console.log(projects)
    // Apply Filters
    if(props.schedacommessa.project_filters.x_studio_close_project.length > 0){
        projects = projects.filter(item => props.schedacommessa.project_filters.x_studio_close_project.includes(item.x_studio_close_project))
        console.log(projects);
    }
    if(props.schedacommessa.project_filters.company_id.length > 0){
        projects = projects.filter(item => props.schedacommessa.project_filters.company_id.includes(item.company_id[1]))
        console.log(projects);
    }
    if(props.schedacommessa.project_filters.user_id.length > 0){
        projects = projects.filter(item => props.schedacommessa.project_filters.user_id.includes(item.user_id[1]))
        console.log(projects);
    }
    if(props.schedacommessa.project_filters.partner_id.length > 0){
        projects = projects.filter(item => props.schedacommessa.project_filters.partner_id.includes(item.partner_id[1]))
        console.log(projects);
    }
    if(props.schedacommessa.project_filters.name.length > 0){
        projects = projects.filter(item1 => props.schedacommessa.project_filters.name.some(item => item1.name.toLowerCase().indexOf(item.toLowerCase()) !== -1))
        console.log(projects);
    }



    return (
    <div>
      
    <Collapse style={{marginLeft:"12px",marginRight:"12px"}} defaultActiveKey={['1']}>
      <Collapse.Panel header="Filtri" key="1">
        {
            props.schedacommessa.flag_get_projects == true ?
        <div>
            <Row gutter={[16, 16]}>
                <FiltroTag act_filter={props.SetSelectedFilter} prjlist={props.schedacommessa.projects_list} label={'Stato Progetto'} chiave={'x_studio_close_project'} filters={props.schedacommessa.project_filters} />
                <FiltroTag act_filter={props.SetSelectedFilter} prjlist={props.schedacommessa.projects_list} label={'Company'} chiave={'company_id'} filters={props.schedacommessa.project_filters}/>
                <FiltroTag act_filter={props.SetSelectedFilter} prjlist={props.schedacommessa.projects_list} label={'PM'} chiave={'user_id'} filters={props.schedacommessa.project_filters}/>
                <FiltroTag act_filter={props.SetSelectedFilter} prjlist={props.schedacommessa.projects_list} label={'Customer'} chiave={'partner_id'} filters={props.schedacommessa.project_filters}/>
            </Row>
            <Row gutter={[16, 16]}>
                <FiltroNome act_filter={props.SetSelectedFilter} label={'Nome Progetto'} chiave={'name'} filters={props.schedacommessa.project_filters} />
            </Row>
        </div>
         : 
         <div><center>Caricamento in Corso....</center></div>
        }

      </Collapse.Panel>
    </Collapse>
    
    <Card title="Elenco Progetti">
        <Card.Grid style={{width:"0px","position":"absolute","opacity":"0"}}></Card.Grid>
        {
            projects.map((project) => {
                return(
                <CardProject sendprjbe={props.SendProjectToBE} update_sel_prj={props.UpdateSelectedProject} project={project} />
                )
            })
        }
    </Card>
    </div>
    )
}





export default RicercaProgetti






/*




    const GetProjects = () => {
        if (header.user_rights.scheda_commessa_user && schedacommessa.flag_get_projects === false) {
            if (schedacommessa.active_projects) {
                GetProjectsList(login.info_login.user_id, false)
            }
            if (schedacommessa.all_projects) {
                GetProjectsList(login.info_login.user_id, true)
            }
        }
        if (header.user_rights.scheda_commessa_admin && schedacommessa.flag_get_projects === false) {
            if (schedacommessa.active_projects) {
                GetProjectsList(undefined, false)
            }
            if (schedacommessa.all_projects) {
                GetProjectsList(undefined, true)
            }
        }
    }

    const ProjectOptions = []

    if (schedacommessa.flag_get_projects === true) {
        try {
            schedacommessa
                .projects_list
                .map(function (progetto, index) {
                    ProjectOptions.push({
                        key: '0' + index,
                        value: progetto.id,
                        text: progetto.name,
                        project: progetto
                    })
                })
        } catch (e) {}
    }

    const meseOptions = [
        {
            key: '01',
            value: '01',
            text: 'Gennaio'
        }, {
            key: '02',
            value: '02',
            text: 'Febbraio'
        }, {
            key: '03',
            value: '03',
            text: 'Marzo'
        }, {
            key: '04',
            value: '04',
            text: 'Aprile'
        }, {
            key: '05',
            value: '05',
            text: 'Maggio'
        }, {
            key: '06',
            value: '06',
            text: 'Giugno'
        }, {
            key: '07',
            value: '07',
            text: 'Luglio'
        }, {
            key: '08',
            value: '08',
            text: 'Agosto'
        }, {
            key: '09',
            value: '09',
            text: 'Settembre'
        }, {
            key: '10',
            value: '10',
            text: 'Ottobre'
        }, {
            key: '11',
            value: '11',
            text: 'Novembre'
        }, {
            key: '12',
            value: '12',
            text: 'Dicembre'
        }
    ]

    const generate_anni = () => {
        let historyyear = 3
        let yearless3 = moment()
            .subtract(historyyear, 'years')
            .format('YYYY')

        let annoOptions = []

        for (let i = 0; i <= historyyear; i++) {
            annoOptions.push({
                key: (parseInt(yearless3) + i) + '',
                value: (parseInt(yearless3) + i) + '',
                text: (parseInt(yearless3) + i) + ''
            })
        }

        return annoOptions
    }

    const annoOptions = generate_anni()

    const UpdateCurrentProjectInfo = (e, data) => {
        var project = data
            .options
            .filter((opzione) => {
                return opzione.value == data.value
            })
        UpdateSelectedProject(project[0].project)
    }

    var sel_month
    if (schedacommessa.selected_date != null) {
        sel_month = moment(schedacommessa.selected_date).format('MM')
    } else {
        sel_month = null
    }

    const UpdateSelectMonth = (e, data) => {
        var month = data
            .options
            .filter((opzione) => {
                return opzione.value == data.value
            })
        sel_month = (month[0].value)
        if (sel_month != null && sel_year != null) {
            var concat_date = sel_year + '-' + sel_month + '-01'
            var calc_last_day_month = moment(concat_date).daysInMonth()
            var correct_date = sel_year + '-' + sel_month + '-' + calc_last_day_month
            UpdateSelectedDateRange(correct_date)
        }
    }

    var sel_year
    if (schedacommessa.selected_date != null) {
        sel_year = moment(schedacommessa.selected_date).format('YYYY')
    } else {
        sel_year = null
    }

    const UpdateSelectYear = (e, data) => {
        var year = data
            .options
            .filter((opzione) => {
                return opzione.value == data.value
            })
        sel_year = (year[0].value)
        if (sel_month != null && sel_year != null) {
            var concat_date = sel_year + '-' + sel_month + '-01'
            var calc_last_day_month = moment(concat_date).daysInMonth()
            var correct_date = sel_year + '-' + sel_month + '-' + calc_last_day_month
            UpdateSelectedDateRange(correct_date)
        }
    }

    if ((header.active_menu.scheda_commessa_user || header.active_menu.scheda_commessa_admin) && schedacommessa.is_project_ok === false) {
        return (
            <center>
                <div id="sceltaprogetto_riquadro">
                    <Grid.Row>
                        <Grid.Column width={1}></Grid.Column>

                        <Grid.Column width={3}>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row>

                                <RadioGroup id='radio' aria-label="projects" name="projects1">
                                    <FormControlLabel
                                        value="active_project"
                                        checked={schedacommessa.active_projects}
                                        control={< Radio />}
                                        label="Mostra tutti i progetti Attivi"
                                        onClick={OnlyActiveProjects}/>
                                    <FormControlLabel
                                        value="all_projects"
                                        checked={schedacommessa.all_projects}
                                        control={< Radio />}
                                        label="Mostra tutti i progetti Chiusi"
                                        onClick={AllProjects}/>
                                </RadioGroup>

                            </Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>

                            <Grid.Row>
                                <Dropdown
                                    id='lista_prog'
                                    placeholder='Select Project'
                                    fluid
                                    search
                                    selection
                                    closeOnChange
                                    options={ProjectOptions}
                                    onClick={GetProjects}
                                    onChange={UpdateCurrentProjectInfo}/>
                            </Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row className='Spazio'></Grid.Row>
                            <Grid.Row>
                                <> <center>
                                    <Button
                                        disabled={!schedacommessa.is_project_selected}
                                        onClick={SendProjectToBE}
                                        id='ok'>OK</Button>
                                </center>
                            </>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={12}></Grid.Column>
                </Grid.Row>
            </div>
        </center>
    } else {
        return (
            <div></div >
        )
    }


*/