import React, {useState} from 'react'
import moment from 'moment'
import ReactDataGrid, {Row, Cell} from 'react-data-grid';
import  {Popconfirm, Col as ColAnt, Row as RowAnt, Tooltip} from 'antd';
import * as jquery from 'jquery';
import '../scheda_commessa/DataGridStyle.css'
import {connect} from 'react-redux'
import {IconButton, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import { Icon } from 'semantic-ui-react'
import RicercaProgetti from './elenco_progetti/elenco_progetti_view.js'
import "moment/locale/it"
import {
    DateInput,
    TimeInput,
    DateTimeInput,
    DatesRangeInput
  } from 'semantic-ui-calendar-react';
import {
    Image,
    Grid,
    Message,
    Button,
    Dropdown,
    Header,
    GridColumn,
    Table,
    TableHeaderCell,
    Input,
    Modal,
    List
} from 'semantic-ui-react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import {green, red} from '@material-ui/core/colors';
import {TryDoLoginOdoo} from "../login_page/login_actions"
import {
    OnlyActiveProjects,
    AllProjects,
    UpdateSelectedProject,
    SendProjectToBE,
    GetProjectsList,
    GetProjectInfo,
    ProjectReadyToRender,
    ResetGetProjectInfo,
    HandleEditCells,
    CreateXLS_SchedaCommessa,
    UpdateAllPrevisionatoData,
    UpdateSelectedDateRange,
    GetInfoEmpContracts,
    AddEmpToSchedaCommessa,
    AddDeletePrevisionato,
    XLSDownloaded,
    SetNewStartDate,
    SetNewEndDate,
    GenerateLastUpdatePM,
    UpdateGenInfo,
    GetFollowersProject,
    AddFollowerProject,
    RemoveFollowerProject,
    GetAllProjects,
    SetSelectedFilter,
} from './schedacommessa_actions'
import parser from 'multi-number-parse';

const toNumber = require("strnum");

var HashMap = require('hashmap');

var natural = require("natural-compare")

const formatDecimal = require('format-decimal');
moment.locale('it')

const SchedaCommessaTabella = ({
    login,
    schedacommessa,
    GetProjectInfo,
    ProjectReadyToRender,
    ResetGetProjectInfo,
    HandleEditCells,
    UpdateAllPrevisionatoData,
    GetInfoEmpContracts,
    GetFollowersProject,
    AddEmpToSchedaCommessa,
    AddDeletePrevisionato,
    AddFollowerProject,
    RemoveFollowerProject,
    SetNewStartDate,
    SetNewEndDate,
    XLSDownloaded,
    CreateXLS_SchedaCommessa,
    GenerateLastUpdatePM,
    UpdateGenInfo,
    GetAllProjects,
    header
}) => {


    // Recupero informazioni Scheda Commessa in base al Progetto

    var colonne_maturato = [...schedacommessa.colonne_maturato]
    var colonne_previsionato = [...schedacommessa.colonne_previsionato]
    var InitPrevisionatoDay = new HashMap()
    var InitPrevisionatoExpense = new HashMap()
    var righe = {}

    if (schedacommessa.selected_date != null) {
        schedacommessa.info_project.previsionato = []
        schedacommessa.info_project.previsionato_expenses = []
    }

    if (schedacommessa.is_project_ok) {
        if (schedacommessa.is_info_project_get) {


            // Controllo se disponibile il Download Del FILE_XLS
            if(schedacommessa.xls_generated){

                //ultimo mese maturato
                var colonneordinatemat  = schedacommessa.colonne_maturato.sort((a,b) => new moment(a.key.replace('date_','')).format('YYYYMMDD') - new moment(b.key.replace('date_','')).format('YYYYMMDD'))

                var a = document.createElement("a"); //Create <a>
                a.href = "data:application/octet-stream;base64," + schedacommessa.base64_xls.base64; //Image Base64 Goes here
                try {
                    a.download = moment(colonneordinatemat[colonneordinatemat.length - 1].key.replace('date_','')).format('MMYYYY') + ' - ' + schedacommessa.selected_project.name + ".xlsx"; //File name Here
                } catch (error) {
                    a.download = moment().format('MMYYYY') + ' - ' + schedacommessa.selected_project.name + ".xlsx"; //File name Here
                }
                a.click(); //Downloaded file
                a.remove(); //RemoveLink
                XLSDownloaded()
            }

            if (schedacommessa.is_project_ready_render == false) {

                var minmaxdate_maturato = []
                var minmaxdate_prevsionato = []

                // genero i mesi di maturato da mettere in colonna
                schedacommessa
                    .info_project
                    .maturato
                    .map((row) => {

                        var profilo = row.company == row.company_id ? row.profilo : (row.x_studio_external == "1" ? row.profilo : row.profilo_ic)
                        var profilo_id = JSON.parse(profilo)[0]
                        var profilo_name = JSON.parse(profilo)[1]


                        minmaxdate_maturato.push(row.mese_del_timesheet)
                        var keyid = '' + row.nome_utente + profilo_id

                        var costo = row.company == row.company_id ? row.costo_interno : (row.x_studio_external == "1" ? row.costo_interno : row.costo_esterno)
                            
                        if (typeof(righe[keyid]) === "undefined") {

                            righe[keyid] = {}
                            righe[keyid]['costo_profilo'] = 0
                            righe[keyid]['costo_timesheet_mese'] = 0
                            righe[keyid]['giorni_timesheet_mese'] = 0
                            righe[keyid]['costo_trasferta_maturato'] = 0
                            righe[keyid]['giorni_timesheet_previsto'] = 0              
                            righe[keyid]['costo_trasferta_previsto'] = 0
                            righe[keyid]['costo_timesheet_previsto'] = 0
                            righe[keyid]['previsionato_days_info'] = []
                            righe[keyid]['previsionato_exp_info'] = []

                        } else {
                            //Calcolo Costo giornaliero, Maturato in base alla company
                            //row.costo_interno row.costo_esterno
                        }

                        row.mese_del_timesheet = row
                            .mese_del_timesheet
                            .replace('31', '01')
                        righe[keyid]['consulente'] = row.nome_utente
                        righe[keyid]['user_id'] = login.info_login.user_id
                        righe[keyid]['self'] = login.info_login.self
                        righe[keyid]['keyid'] = keyid
                        righe[keyid]['employee_id'] = JSON.parse(row.employee_id)
                        righe[keyid]['x_studio_date'] = row.mese_del_timesheet
                        righe[keyid]['profilo'] = profilo_name.split(" ")[0]
                        righe[keyid]['costo_profilo'] = formatDecimal(costo, {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                        righe[keyid]['giorni_timesheet_mese'] = formatDecimal((parser(righe[keyid]['giorni_timesheet_mese']) + parser(row.giorni_maturati_mese)), {decimal: ',',precision: 2,thousands: '.'})
                        righe[keyid]['costo_timesheet_mese'] = parser(righe[keyid]['costo_timesheet_mese']) + (parser(row.giorni_maturati_mese) * costo)//parser(row.maturato_mese)

                        if (row.maturato_expenses_mese !== null) {
                            righe[keyid]['costo_trasferta_maturato'] = parser(righe[keyid]['costo_trasferta_maturato']) + parser(row.maturato_expenses_mese)
                        }

                        righe[keyid]['date_' + moment(row.mese_del_timesheet).format('YYYY-MM-01')] = row.giorni_maturati_mese
                    })

                schedacommessa
                    .info_project
                    .previsionato
                    .map((row) => {
                        minmaxdate_prevsionato.push(row.mese_del_timesheet)
                        var keyid = '' + row.nome_utente + row
                            .profilo
                            .split('"')
                            .join('')
                        keyid = keyid
                            .split(' ')
                            .join('')
                        row.mese_del_timesheet = row
                            .mese_del_timesheet
                            .replace('31', '01')
                        if (typeof(righe[keyid]) === "undefined") {
                            righe[keyid] = {}
                            righe[keyid]['previsionato_days_info'] = []
                            righe[keyid]['previsionato_exp_info'] = []  
                            righe[keyid]['giorni_timesheet_previsto'] = 0
                            righe[keyid]['costo_timesheet_mese'] = 0
                            righe[keyid]['costo_timesheet_previsto'] = 0
                            righe[keyid]['giorni_timesheet_mese'] = 0
                            righe[keyid]['costo_timesheet_mese'] = 0
                            righe[keyid]['costo_trasferta_maturato'] = 0
                            righe[keyid]['costo_trasferta_previsto'] = 0
                            righe[keyid]['keyid'] = keyid
                            righe[keyid]['employee_id'] = JSON.parse(row.x_studio_employee)
                        }

                        righe[keyid]['previsionato_days_info'] = schedacommessa.info_project.previsionato                   
                        righe[keyid]['consulente'] = row.nome_utente
                        righe[keyid]['user_id'] = login.info_login.user_id
                        righe[keyid]['self'] = login.info_login.self
                        //righe[keyid]['profilo'] = ""
                        if (row.x_studio_days > 0) {
                            // change LS Ottobre 2024 righe[keyid]['costo_timesheet_previsto'] = parser(righe[keyid]['costo_timesheet_previsto']) + (parseFloat(righe[keyid]['costo_profilo'].replace(".","").replace(",",".")) * parser(row.x_studio_days))
                            righe[keyid]['date_' + moment(row.mese_del_timesheet).format('YYYY-MM-01')] = row.x_studio_days
                            righe[keyid]['giorni_timesheet_previsto'] = formatDecimal(parser(righe[keyid]['giorni_timesheet_previsto']) + parser(row.x_studio_days), {decimal: ',',precision: 2,thousands: '.'})
                        }

                        row['ignore'] = true
                        InitPrevisionatoDay.set('date_' + moment(row.mese_del_timesheet).format('YYYY-MM-01') + keyid, row)

                    })

                schedacommessa
                    .info_project
                    .previsionato_expenses
                    .map((row) => {
                        var keyid = '' + row.nome_utente + row
                            .profilo
                            .split('"')
                            .join('')
                        keyid = keyid
                            .split(' ')
                            .join('')
                        if (typeof(righe[keyid]) === "undefined") {
                            righe[keyid] = {}
                            righe[keyid]['previsionato_days_info'] = []
                            righe[keyid]['previsionato_exp_info'] = []
                            righe[keyid]['giorni_timesheet_previsto'] = 0
                            righe[keyid]['costo_timesheet_mese'] = 0
                            righe[keyid]['costo_timesheet_previsto'] = 0    
                            righe[keyid]['giorni_timesheet_mese'] = 0
                            righe[keyid]['costo_timesheet_mese'] = 0
                            righe[keyid]['costo_trasferta_maturato'] = 0
                            righe[keyid]['costo_trasferta_previsto'] = 0
                            righe[keyid]['consulente'] = row.nome_utente
                            righe[keyid]['user_id'] = login.info_login.user_id
                            righe[keyid]['self'] = login.info_login.self
                            righe[keyid]['keyid'] = keyid
                            righe[keyid]['profilo'] = row
                                .profilo
                                .split('"')
                                .join('')
                        }
                        righe[keyid]['consulente'] = row.nome_utente
                        righe[keyid]['profilo'] = row
                            .profilo
                            .split('"')
                            .join('')

                        righe[keyid]['costo_trasferta_previsto'] = row.x_studio_expenses
                        righe[keyid]['previsionato_exp_info'] = schedacommessa.info_project.previsionato_expenses

                        row['ignore'] = true
                        InitPrevisionatoExpense.set('costo_trasferta_previsto' + keyid, row)

                    })

                // Inizio Colonne Aggiuntive

                // MATURATO
                function onlyUnique(value, index, self) {
                    return self.indexOf(value) === index;
                }
                
                minmaxdate_maturato = minmaxdate_maturato.sort(function (d1, d2) {
                    return moment(d1).isBefore(moment(d2));
                });
                minmaxdate_maturato = minmaxdate_maturato.filter(onlyUnique);

                minmaxdate_prevsionato = minmaxdate_prevsionato.sort(function (d1, d2) {
                    return moment(d1).isBefore(moment(d2));
                });

                minmaxdate_prevsionato = minmaxdate_prevsionato.filter(onlyUnique);

                minmaxdate_prevsionato = minmaxdate_prevsionato.sort(function (d1, d2) {
                    return moment(d1).format('YYYY-MM-01') - moment(d2).format('YYYY-MM-01')
                });


                if(minmaxdate_maturato.length > 0){
                    minmaxdate_maturato.map((date) => {
                        date = date.replace('31', '01')
                        colonne_maturato.push({
                            key: 'date_' + moment(date).format('YYYY-MM-01'),
                            name: '' + moment(date).format('MMM YY'),
                            editable: false,
                            width: '7%',
                            formatter: (props) => {
                                if(props.rowIdx === Object.keys(righe).length - 1){
                                    //console.log(props.rowIdx, "-->", Object.keys(righe).length - 1, "valore:", props.value )
                                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                                } else {
                                    return <div id="allineamento_numeri_destra_scuro">{props.value === undefined ? "　" : props.value}</div>
                                }
                            } 
                        })
                    })
                }
                 

                // LIST MESI TRA MAXDATE MATURATO E LA SELECTED ENDDATE PREVISIONATO E
                var dateStart = moment(schedacommessa.info_project.ultima_chiusura_mensile).add(1,'months')
                var dateEnd = moment(schedacommessa.end_date);
                if(moment(dateEnd).format('YYYY-MM-DD') === '2999-12-31'){
                    dateEnd = moment(moment().format('YYYY-12-31'))
                }   
                var interim = dateStart.clone();
                var timeValues = [];
                
                while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
                   timeValues.push(interim.format('YYYY-MM-01'));
                   interim.add(1,'month');
                }

                timeValues.map((date) => {
                    colonne_previsionato.push({
                        key: 'date_' + moment(date).format('YYYY-MM-01'),
                        name: '' + moment(date).format('MMM YY'),
                        editable: true,
                        width: '7%',
                         formatter: (props) => {
                            console.log(props.rowIdx, Object.keys(righe).length - 1 )
                            if(props.rowIdx === Object.keys(righe).length - 1){
                                return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                            } else {
                                return <div id="allineamento_numeri_destra_chiaro">{props.value === undefined ? "　" : props.value}</div>
                            }
                        }
                    },)
                })

            // Fine Colonne Aggiuntive

             // aggiungo la riga si somma

             delete righe['somma_verticale']
             righe['somma_verticale'] = {}
             righe['somma_verticale']['keyid'] = 'somma_verticale'
             righe['somma_verticale']['consulente'] = '-- Somma Totale --'
             righe['somma_verticale']['giorni_timesheet_previsto'] = 0
             righe['somma_verticale']['costo_timesheet_previsto'] = 0
             righe['somma_verticale']['costo_trasferta_previsto'] = 0
             righe['somma_verticale']['giorni_timesheet_mese'] = 0
             righe['somma_verticale']['costo_trasferta_maturato'] = 0
             righe['somma_verticale']['costo_timesheet_mese'] = 0
              
             Object.entries(righe).map((value, index, array) => {
                 if(value[0] === 'somma_verticale'){

                     value = value[1]
                     righe['somma_verticale']['costo_timesheet_previsto'] = formatDecimal(righe['somma_verticale']['costo_timesheet_previsto'], {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                     righe['somma_verticale']['costo_trasferta_previsto'] = formatDecimal(righe['somma_verticale']['costo_trasferta_previsto'], {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                     righe['somma_verticale']['costo_trasferta_maturato'] = formatDecimal(righe['somma_verticale']['costo_trasferta_maturato'], {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                     righe['somma_verticale']['costo_timesheet_mese'] = formatDecimal(righe['somma_verticale']['costo_timesheet_mese'], {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                 
                 } else {
                     // cambio e formatto i decimali
                     var valuex = value[1]

                                          // ciclo le date
                                          Object.keys(valuex).map((prop, index, array) => {
                                            // controllo se la property è una data
                                                if(prop.includes('date_')){
                                     
                                                    // controllo se esiste la proprieta altrimenti aggiungo e sommo
                                                    if(righe['somma_verticale'][prop] === undefined){
                                                        righe['somma_verticale'][prop] = 0
                                                        righe['somma_verticale'][prop] = parser(righe['somma_verticale'][prop]) + parser(valuex[prop])
                                                    } else {
                                                        righe['somma_verticale'][prop] = parser(righe['somma_verticale'][prop]) + parser(valuex[prop])
                                                    }
                                     
                                                }
                                       
                                            })

                     if(righe[value[0]]['costo_timesheet_previsto'] != undefined){
                       righe['somma_verticale']['costo_timesheet_previsto'] = parser(righe['somma_verticale']['costo_timesheet_previsto']) + parser(valuex.costo_timesheet_previsto)      
                       righe[value[0]]['costo_timesheet_previsto'] = formatDecimal(parser(righe[value[0]]['costo_timesheet_previsto']), {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                     }

                     if(righe[value[0]]['costo_trasferta_previsto'] != undefined){
                       righe['somma_verticale']['costo_trasferta_previsto'] = parser(righe['somma_verticale']['costo_trasferta_previsto']) + parser(valuex.costo_trasferta_previsto)
                       righe[value[0]]['costo_trasferta_previsto'] = formatDecimal(parser(righe[value[0]]['costo_trasferta_previsto']), {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                     }

                     if(righe[value[0]]['costo_trasferta_maturato'] != undefined){
                       righe['somma_verticale']['costo_trasferta_maturato'] = parser(righe['somma_verticale']['costo_trasferta_maturato']) + parser(valuex.costo_trasferta_maturato)
                       righe[value[0]]['costo_trasferta_maturato'] = formatDecimal(parser(righe[value[0]]['costo_trasferta_maturato']), {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                     }
                     
                     if(righe[value[0]]['costo_timesheet_mese'] != undefined){
                       righe['somma_verticale']['costo_timesheet_mese'] = parser(righe['somma_verticale']['costo_timesheet_mese']) + parser(valuex.costo_timesheet_mese)
                       righe[value[0]]['costo_timesheet_mese'] = formatDecimal(parser(righe[value[0]]['costo_timesheet_mese']), {decimal: ',',precision: 2,thousands: '.'}) + ' €'      
                     }
            
                     if(righe[value[0]]['giorni_timesheet_previsto'] != undefined){
                       righe['somma_verticale']['giorni_timesheet_previsto'] = formatDecimal(parser(righe['somma_verticale']['giorni_timesheet_previsto']) + parser(valuex.giorni_timesheet_previsto), {decimal: ',',precision: 2,thousands: '.'})
                     }
            
                     if(righe[value[0]]['giorni_timesheet_mese'] != undefined){
                       righe['somma_verticale']['giorni_timesheet_mese'] = formatDecimal(parser(righe['somma_verticale']['giorni_timesheet_mese']) + parser(valuex.giorni_timesheet_mese), {decimal: ',',precision: 2,thousands: '.'})
                     }
                 }
                 })

                if(schedacommessa.start_date === '' || schedacommessa.end_date === ''){
                    righe = {}
                    colonne_maturato  = []
                    colonne_previsionato = []
                }

                ProjectReadyToRender(minmaxdate_maturato, minmaxdate_prevsionato, colonne_maturato, colonne_previsionato, righe, InitPrevisionatoDay, InitPrevisionatoExpense)
            }
        } else {
            //Chiamo API per prendere il progetto dal Backend
            if (schedacommessa.selected_date != null) {
                GetProjectInfo(schedacommessa.selected_project.id, schedacommessa.selected_project.active, schedacommessa.selected_date)
            } else {
                GetProjectInfo(schedacommessa.selected_project.id, schedacommessa.selected_project.active)
            }
        }
    }

    const COLUMN_WIDTH = 10;

    const col_intestazione = [
        {
            key: 'progetto',
            name: schedacommessa.selected_project.name,
            editable: true,
            width: '11%',
            frozen: true,
        }, {
            key: '',
            name: '',
            editable: true,
            width: '3%',
            frozen: true
        }, {
            key: 'costi_costo',
            name: 'Costi',
            editable: true,
            width: '18%',
            frozen: true,
        }, {
            key: 'costi_t',
            name: 'Trasferta',
            editable: true,
            width: '12%',
            frozen: true
        }, {
            key: 'costi_g',
            name: 'Giorni',
            editable: true,
            width: '56%',
            frozen: true
        }

    ];
    const row_intestazione = [];

    const colonnefisse = [
        {
            key: 'consulente',
            name: 'Consulente',
            sortable: true,
            filterable: true,
            editable: false,
            width: '11%',
            frozen: true,
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value}</div>
                } else {
                    return <div style={{background: '#daf0f6'}}>{props.value}</div>
                }
            }
        }, {
            key: 'profilo',
            name: 'Livello',
            editable: false,
            width: '3%',
            frozen: true,
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                } else {
                    return <div style={{background: 'rgb(238, 238, 238)'}}>{props.value === undefined ? "　" : props.value}</div>
                }
            }
        }, {
            key: 'costo_profilo',
            name: 'Costo STD',
            editable: false,
            width: '6%',
            frozen: true,
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                } else {
                    return <div id="allineamento_numeri_destra_chiaro">{props.value === undefined ? "　" : props.value}</div>
                }
            }
        }, {
            key: 'costo_timesheet_mese',
            name: 'Maturato',
            editable: false,
            width: '6%',
            frozen: true,
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                } else {
                    return <div id="allineamento_numeri_destra_scuro">{props.value === undefined ? "　" : props.value}</div>
                }
            }
        }, {
            key: 'costo_timesheet_previsto',
            name: 'Previsto a finire',
            editable: false,
            width: '6%',
            frozen: true,
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                } else {
                    return <div id="allineamento_numeri_destra_chiaro">{props.value === undefined ? "　" : props.value}</div>
                }
            }
        }, {
            key: 'costo_trasferta_maturato',
            name: 'Maturato',
            editable: false,
            width: '6%',
            frozen: true,
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                } else {
                    return <div id="allineamento_numeri_destra_scuro">{props.value === undefined ? "　" : props.value}</div>
                }
            }
        }, {
            key: 'costo_trasferta_previsto',
            name: 'Previsto a finire',
            editable: true,
            width: '6%',
            frozen: true,
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                } else {
                    return <div id="allineamento_numeri_destra_chiaro">{props.value === undefined ? "　" : props.value}</div>
                }
            }
        }, {
            key: 'giorni_timesheet_mese',
            name: 'Maturato',
            editable: false,
            width: '6%',
            frozen: true,                            
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                } else {
                    return <div id="allineamento_numeri_destra_scuro">{props.value === undefined ? "　" : props.value}</div>
                }
            }
        }, {
            key: 'giorni_timesheet_previsto',
            name: 'Previsto a finire',
            editable: false,
            width: '6%',
            frozen: true,
            formatter: (props) => {
                if(props.rowIdx === Object.keys(schedacommessa.righe_scheda).length - 1){
                    return <div id="colore_ultima_riga">{props.value === undefined ? "　" : props.value}</div>
                } else {
                    return <div id="allineamento_numeri_destra_chiaro">{props.value === undefined ? "　" : props.value}</div>
                }
            }

        }
    ]

    var colonnedinamiche = [
        ...colonne_maturato,
        ...colonne_previsionato
    ]

    // CheckDuplcati
    var app_array_key = []
    var unique_columns = []

    colonnedinamiche.map((colonna) => {
        if(app_array_key.includes(colonna.key)){
        } else {
            unique_columns.push(colonna)
            app_array_key.push(colonna.key)
        }
    })
    colonnedinamiche = unique_columns

    // Ordino nuovamente le colonne delle date in ordine alfabetico

    // prendo tutte le colonne che hanno date_
    colonnedinamiche  = colonnedinamiche.sort((a,b) => new moment(a.key.replace('date_','')).format('YYYYMMDD') - new moment(b.key.replace('date_','')).format('YYYYMMDD'))




   /*  const [needUpdateGenInfo,setneedUpdateGenInfo] = useState(true);
    if(needUpdateGenInfo){
        UpdateGenInfo({a:'test'})
        setneedUpdateGenInfo(false)
    } */


    // Ordino i consulenti Per ordine Alfabetico e rimetto infondo Somma Totale

    

    function getAllDatesOfMonth(date) {
        const mDate = moment(date, "YYYY-MM");
        const daysCount = mDate.daysInMonth();
        return Array(daysCount).fill(null).map((v,index)=>{
            const addDays = index === 0 ? 0 : 1;
            return mDate.add(addDays, 'days').format('YYYY-MM-DD');
        });
    }
    // loop sulle solonne dinamiche dei mesi

    colonnedinamiche.map((colonna,index) => {

        var data_colonna = moment(colonna.key.replace('date_',''))
        var nome_colonna = colonna.name

        if(nome_colonna.length < 7){

            var g_lavorativi = 0

            getAllDatesOfMonth(data_colonna).map((giorno) => {
                if(moment(giorno).format('dddd') == 'sabato' || moment(giorno).format('dddd') == 'domenica'){
                } else {
                    g_lavorativi = g_lavorativi + 1
                }

            })

            colonnedinamiche[index].name = nome_colonna + ' (' + g_lavorativi + ')'

        }

    })



    const columns = [
        ...colonnefisse,
        ...colonnedinamiche
    ];

    const rows = [];

    Object
        .entries(schedacommessa.righe_scheda)
        .map((value, index, array) => {
            rows.push(schedacommessa.righe_scheda[value[0]])
        })

    const rowGetterIntestazione = rowNumber_int => row_intestazione[rowNumber_int];
    const rowGetter = rowNumber => rows[rowNumber];

    const update = (e) => {
        if (schedacommessa.selected_date === null) {
            HandleEditCells(e)
        }
    }

    const getCellActions = (columns, row) => {
        
        var IdsToDelete = []
        var KeyIdsToDelete = []

        if(row.previsionato_days_info != undefined){
            row.previsionato_days_info.map((row_day) => {
                if(row_day.nome_utente === row.consulente){
                    IdsToDelete.push(row_day.id)
                    KeyIdsToDelete.push(row.keyid)
                }
            })
        }   

        if(row.previsionato_exp_info != undefined){
            row.previsionato_exp_info.map((row_exp) => {
                if(row_exp.nome_utente === row.consulente){
                    IdsToDelete.push(row_exp.id)
                    KeyIdsToDelete.push(row.keyid)
                }
            })
        }

        const cellActions = {
            consulente: []
        };

        if(row.keyid != 'somma_verticale'){
            if(parser(row.costo_timesheet_mese) === 0 && parser(row.costo_trasferta_maturato) === 0){
                cellActions.consulente.push(
                    {
                        icon: <Icon id="color_icon" name='trash alternate outline' />,
                        callback: () => {
                          AddDeletePrevisionato([...new Set(IdsToDelete)],[...new Set(KeyIdsToDelete)] )
                        }
                    },
                )
            }
            if (schedacommessa.followers?.includes(row.employee_id?.[0])) {
            // LS Change riferimento mancante if(schedacommessa.followers.includes(row.employee_id[0])){
                if(parser(row.costo_timesheet_mese) === 0 && parser(row.costo_trasferta_maturato) === 0){
                    var elem = <Tooltip placement="right" color="grey" title="Remove from timesheet"><RemoveIcon fontSize="small" style={{ color: red[500] }} /></Tooltip>
                    cellActions.consulente.push(
                        {
                            icon: elem,
                            callback: () => {
                                RemoveFollowerProject(schedacommessa.selected_project.id,row.employee_id[0])
                            }
                        } 
                    )
                } else {
                    //<CheckIcon fontSize="small" style={{ color: green[500] }} />
                    var elem = <Tooltip placement="right" color="grey" title="Added to timesheet"><CheckIcon fontSize="small" style={{ color: green[500] }} /></Tooltip>
                    cellActions.consulente.push(
                        {
                            icon: elem,
                            callback: () => {}
                        } 
                    )
                }
                
            } else {
                var elem = <Tooltip placement="right" color="grey" title="Add to timesheet"><AddIcon fontSize="small" style={{ color: red[500] }} /></Tooltip>
                cellActions.consulente.push(
                    {
                        icon: elem,
                        callback: () => {
                            AddFollowerProject(schedacommessa.selected_project.id,row.employee_id[0])
                        }
                    } 
                )
            }
            return cellActions[columns.key]
        }

        return null
        
    }


    const UpdatePrevisionato = () => {
        UpdateAllPrevisionatoData(schedacommessa.selected_project.id,schedacommessa.start_date,schedacommessa.end_date,schedacommessa.to_delete_previsonato_exp_days, schedacommessa.to_update_previsionato_day, schedacommessa.to_write_previsionato_day, schedacommessa.to_update_previsionato_expense, schedacommessa.to_write_previsionato_expense)
    }

    const ExportGenerate = () => {
        CreateXLS_SchedaCommessa(schedacommessa)
    }

    const RowRenderer = ({ renderBaseRow, ...props }) => {
        if(props.idx === rows.length - 1){
            return <div id="test" style={{backgroundColor: 'red'}}>{renderBaseRow(props)}</div>
        } else {
            return <div>{renderBaseRow(props)}</div>
        }
    };

    if (schedacommessa.is_project_ready_render && (header.user_rights.scheda_commessa_user === true || header.user_rights.scheda_commessa_admin === true )) {

        setTimeout(() => {
            try {
                if (parseInt(document.getElementsByClassName('rdg-cell-frozen')[1].style.width.replace('px', '')) < 100) {
                    var fix1 = document
                        .getElementsByClassName('rdg-viewport')[0]
                        .remove()
                    var fix2 = document.getElementsByClassName('rdg-cell-frozen')[0].style.width = (parseInt(document.getElementsByClassName('rdg-cell-frozen')[0].style.width.replace('px', '')) + parseInt(document.getElementsByClassName('rdg-cell-frozen')[1].style.width.replace('px', ''))) + 'px'
                    var fix3 = document
                        .getElementsByClassName('rdg-cell-frozen')[1]
                        .remove()
                    var fix4 = document.querySelectorAll('.ui.left.icon.input')[0].style.width = '100%'
                    var fix5 = document.querySelectorAll('.ui.left.icon.input')[1].style.width = '100%'
                    /*    var fix6 = document.querySelectorAll('.ui.left.icon.input')[1].style.border = '3px solid red' */
                }
            } catch (error) {}
        }, 1)

        return (
            <div id='piano_contabile'>

            <br></br>
              {/*  nome PM e data ultima modifica  */}
              {GenerateLastUpdate({login, schedacommessa, GenerateLastUpdatePM})}

              <RowAnt>
                <ColAnt flex="32%">
                    {GeneraterAddEmployee({login, schedacommessa, GetInfoEmpContracts,GetFollowersProject, AddEmpToSchedaCommessa})}
                </ColAnt>
                <ColAnt flex="auto" align="center"> 
                    {GenerateDateProject({schedacommessa,SetNewStartDate,SetNewEndDate})}
                </ColAnt>
                <ColAnt flex="30%">
                    <RowAnt justify='center'>
                        <ColAnt flex="28%">
                           <div ><Button basic onClick={ExportGenerate} >DOWNLOAD</Button></div>                      
                        </ColAnt>
                        <ColAnt flex="26%">
                            <div ><Button basic onClick={ResetGetProjectInfo} >AGGIORNA</Button></div>
                        </ColAnt>
                        <ColAnt flex="32%">
                            <div ><Button basic color="red" disabled={schedacommessa.disable_save} onClick={UpdatePrevisionato}>SALVA SU ODOO</Button></div>    
                        </ColAnt>
                    </RowAnt>
                </ColAnt>
                
              </RowAnt>
                <br></br> 

                {/* intestazione della tabella */}
                <ReactDataGrid
                    columns={col_intestazione}
                    rowGetter={rowGetterIntestazione}
                    enableCellSelect={true}
                    onGridRowsUpdated={function () {}}
                    minHeight={-10}
                    className='test'/> {/*  tabella scheda commessa */}
                <div id="color_datagrid">
                    <ReactDataGrid
                    columns={columns}
                    rowGetter={rowGetter}
                    enableCellSelect={true}
                    rowsCount={rows.length}
                    onGridRowsUpdated={update}
                    getCellActions={getCellActions}
                    //rowRenderer={RowRenderer}
                    minHeight={''}/>
                </div>
            </div>
        );

    } else {
        return (
            <div></div>
        )
    }

}

const LoadingDivSpinner = ({schedacommessa}) => {
    if (schedacommessa.loading) {
        return (
            <center>
                <div id="div_spinnger">
                    <CircularProgress id="spinner_color_lobra" size="6rem"/>
                    <h1>Caricamento Progetto in Corso...</h1>
                </div>
            </center>
        )
    } else {
        return (
            <div></div>
        )
    }
}

const GeneratePianoEconomico = ({schedacommessa}) => {

    var costototale_maturato = 0
    var costototale_afinire = 0

    Object
        .entries(schedacommessa.righe_scheda)
        .map((value, index, array) => {
            if(value[1].keyid === 'somma_verticale'){
                if (parser(value[1].costo_timesheet_mese) > 0) {
                    costototale_maturato = parser(costototale_maturato) + parser(value[1].costo_timesheet_mese)
                }
                if (parser(value[1].costo_timesheet_previsto) > 0) {
                    costototale_afinire = parser(costototale_afinire) + parser(value[1].costo_timesheet_previsto)
                }
                if (parser(value[1].costo_trasferta_maturato) > 0) {
                    costototale_maturato = parser(costototale_maturato) + parser(value[1].costo_trasferta_maturato)
                }
                if (parser(value[1].costo_trasferta_previsto) > 0) {
                    costototale_afinire = parser(costototale_afinire) + parser(value[1].costo_trasferta_previsto)
                }
            }
        })
        

    var costototale = costototale_maturato + costototale_afinire

    costototale_maturato = parser(costototale_maturato, ',')
    costototale_afinire = parser(costototale_afinire , ',')

    var totale_piano_economico = schedacommessa.info_project.totale_piano_economico
    if(schedacommessa.info_project.totale_piano_economico === null){
        totale_piano_economico = 0
    }

    var margine = costototale * 100 / totale_piano_economico;

    if (schedacommessa.is_project_ready_render) {
        return (
            <div id='piano_economico'>

                {/*  tabella piano ECONOMICO */}
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <TableHeaderCell id="header_piano_economico" colSpan='3' textAlign='center'>Piano Economico</TableHeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width='nine' colSpan='2'>Costo Maturato YTD</Table.Cell>
                            <Table.Cell width='four'>{(formatDecimal(costototale_maturato, {
                                    decimal: ',',
                                    precision: 2,
                                    thousands: '.'
                                }) + ' €')}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2'>Costo Previsto a Finire YTF</Table.Cell>
                            <Table.Cell>{(formatDecimal(costototale_afinire, {
                                    decimal: ',',
                                    precision: 2,
                                    thousands: '.'
                                }) + ' €')}</Table.Cell>
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer color='blue'>
                        <Table.Row>
                            <Table.HeaderCell id="footer_piano_contabile" colSpan='2'>TOT Costo</Table.HeaderCell>
                            <Table.HeaderCell id="footer_piano_contabile">{(formatDecimal(costototale, {
                                    decimal: ',',
                                    precision: 2,
                                    thousands: '.'
                                }) + ' €')}</Table.HeaderCell>
                        </Table.Row>

                        <Table.Row>
                            <Table.HeaderCell id="footer_piano_contabile">Margine a Finire</Table.HeaderCell>
                            <Table.HeaderCell id="footer_piano_contabile">{(totale_piano_economico === 0 ? '0%' : (100 - (margine).toFixed(2)).toFixed(2) + ' %')}</Table.HeaderCell>
                            <Table.HeaderCell id="footer_piano_contabile">{(formatDecimal(totale_piano_economico - costototale, {
                                    decimal: ',',
                                    precision: 2,
                                    thousands: '.'
                                }) + ' €')}</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell id="footer_piano_contabile">Business Case</Table.HeaderCell>
                            <Table.HeaderCell id="footer_piano_contabile">{(formatDecimal(schedacommessa.selected_project.x_studio_business_case, {
                                    decimal: ',',
                                    precision: 2,
                                    thousands: '.'
                                }) + ' %')}</Table.HeaderCell>
                            <Table.HeaderCell id="footer_piano_contabile">{''}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>

            </div>
        );
    } else {
        return (
            <div></div>
        )
    }
}

const GeneratePianoContabile = ({schedacommessa}) => {
    var is_time_material = schedacommessa.selected_project.x_studio_tm
    var totale_piano_economico = schedacommessa.info_project.totale_piano_economico
    if(schedacommessa.info_project.totale_piano_economico === null){
        totale_piano_economico = 0
    }

    var totale_fatturato = 0
    var totale_da_fatturare = 0


    if('' === ''){
        if (schedacommessa.is_project_ready_render) {
            if (is_time_material) {
                // TIMEMATERIAL
                return (
                    <div id='piano_contabile'>

                        <Table celled structured>
                            <Table.Header>
                                <Table.Row>
                                    <TableHeaderCell id="header_piano_contabile" colSpan='10' textAlign='center'>Piano Contabile</TableHeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Descrizione</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>N° Fattura</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Ordine Lobra</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Ordine Cliente</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Data Fattura</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Data Scadenza Fattura</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Importo fatturato</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Ore Effettuate / Residue</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Importo da fatturare</TableHeaderCell>
                                    <TableHeaderCell id="footer_piano_contabile" textAlign='center'>Incassato</TableHeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {schedacommessa
                                    .info_project
                                    .piano_contabile_fatturato
                                    .map((row) => {
                                        if(row.price_unit == null){
                                            row.price_unit = 0
                                        }
                                        if (row.state == 'posted') {
                                            totale_fatturato = totale_fatturato /*+ (row.price_unit * (row.type_name == 'Credit Note' ? -1 : 1))*/ + row.price_subtotal
                                        } else {
                                            totale_da_fatturare = totale_da_fatturare + (row.invoice_status != 'invoiced' ? row.price_unit : 0)
                                        }
                                        return (
                                            <Table.Row>
                                                <Table.Cell> {/* Descrizione */}
                                                    <center>{row.invoice_name}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* N° Fattura */}
                                                    <center>{row.label_invoice}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ordine Lobra */}
                                                    <center>{row.invoice_origin}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ordine Cliente */}
                                                    <center>{row.x_studio_customer_order_nr}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Data Fattura */}
                                                    <center>{row.state == 'posted'
                                                            ? (moment(row.date_creation_journal).format('DD-MM-YYYY'))
                                                            : ('')}</center>
                                                </Table.Cell>
                                                <Table.Cell>  {/* Data Scadenza Fattura */}
                                                    <center>{row.state == 'posted'
                                                            ? (moment(row.invoice_date_due).format('DD-MM-YYYY'))
                                                            : ('')}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Importo fatturato */}
                                        <center>{(row.type_name == 'Credit Note' ? '-' : '')}{row.state == 'posted'
                                                            ? (formatDecimal(row.price_subtotal, {
                                                                decimal: ',',
                                                                precision: 2,
                                                                thousands: '.'
                                                            }) + ' €')
                                                            : ('')}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ore Effettuate / Residue */}
                                                    <center>{(row.type_name == 'Credit Note' ? '-' : '')}{row.state == 'posted'
                                                            ? (row.quantity)
                                                            : (row.qty_delivered)}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Importo da fatturare */}
                                                        <center>{row.state != 'posted'
                                                            ? (formatDecimal(row.price_unit, {
                                                                decimal: ',',
                                                                precision: 2,
                                                                thousands: '.'
                                                            }) + ' €')
                                                            : ('')}</center>
                                                </Table.Cell>
                                                <Table.Cell>  {/* Incassato */}
                                                    <center>{row.invoice_payment_state != 'paid'
                                                            ? (<CloseIcon
                                                                fontSize="small"
                                                                style={{
                                                                color: red[500]
                                                            }}/>)
                                                            : (<CheckIcon
                                                                fontSize="small"
                                                                style={{
                                                                color: green[500]
                                                            }}/>)}</center>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
    }
                                {schedacommessa
                                    .info_project
                                    .piano_contabile_dafatturare.filter((row) => { return row.price_subtotal != 0})
                                    .map((row) => {
                                        if(row.price_unit == null){
                                            row.price_unit = 0
                                        }
                                        if (row.invoice_payment_state != 'paid') {
                                            totale_fatturato = totale_fatturato + (row.invoice_status == 'invoiced' ? row.price_unit : 0)
                                        } else {
                                            totale_da_fatturare = totale_da_fatturare + (row.invoice_status != 'invoiced' ? row.price_unit : 0)
                                        }
                                        return (
                                            <Table.Row>
                                                <Table.Cell> {/* Descrizione */}
                                                    <center>{row.invoice_name}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* N° Fattura */}
                                                    <center>{row.label_invoice}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ordine Lobra */}
                                                    <center>{row.label_sale_order_id}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ordine Cliente */}
                                                    <center>{row.x_studio_customer_order_nr}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Data Fattura */}
                                                    <center>{''}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Data Scadenza Fattura */}
                                                    <center>{''}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Importo fatturato */}
                                                    <center>{''}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ore Effettuate / Residue */}
                                                    <center>{row.product_uom_qty}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Importo da fatturare */}
                                                    <center>{formatDecimal((row.price_unit * row.product_uom_qty), {
                                                            decimal: ',',
                                                            precision: 2,
                                                            thousands: '.'
                                                        }) + ' €'}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Incassato */}
                                                    <center>{row.invoice_payment_state != 'paid'
                                                            ? (<CloseIcon
                                                                fontSize="small"
                                                                style={{
                                                                color: red[500]
                                                            }}/>)
                                                            : (<CheckIcon
                                                                fontSize="small"
                                                                style={{
                                                                color: green[500]
                                                            }}/>)}</center>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
    }

                            </Table.Body>

                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='6'>Totale Fatturato / Da Fatturare</Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'>
                                        <center>{formatDecimal(totale_fatturato, {
                                                decimal: ',',
                                                precision: 2,
                                                thousands: '.'
                                            }) + ' €'}</center>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'></Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'>
                                        <center>{formatDecimal(totale_da_fatturare, {
                                                decimal: ',',
                                                precision: 2,
                                                thousands: '.'
                                            }) + ' €'}</center>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='2'></Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'>Totale Contrattuale</Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'>
                                        <center>{formatDecimal(totale_piano_economico, {
                                                decimal: ',',
                                                precision: 2,
                                                thousands: '.'
                                            }) + ' €'}</center>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='8'></Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>

                        </Table>

                    </div>
                )
            } else {
                // MILESTONE
                return (
                    <div id='piano_contabile'>

                        <Table celled structured>
                            <Table.Header>
                                <Table.Row>
                                    <TableHeaderCell  id="header_piano_contabile" colSpan='10' textAlign='center'>Piano Contabile</TableHeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <TableHeaderCell textAlign='center'>Descrizione</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>N° Fattura</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>Ordine Lobra</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>Ordine Cliente</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>Data Fattura</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>Data Scadenza Fattura</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>Importo fatturato</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>Data Next Invoice</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>Importo da fatturare</TableHeaderCell>
                                    <TableHeaderCell textAlign='center'>Incassato</TableHeaderCell>

                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {schedacommessa
                                    .info_project
                                    .piano_contabile_fatturato
                                    .map((row) => {
                                            if(row.price_subtotal == null){
                                                row.price_subtotal = 0
                                            }
                                            if(row.type_name == 'Credit Note'){
                                                totale_fatturato = parser(totale_fatturato + '') - parser(row.price_subtotal)
                                            } else {
                                                totale_fatturato = parser(totale_fatturato + '') + parser(row.price_subtotal)
                                            }      
                                            //totale_fatturato = parser(totale_fatturato + '') + parser(((row.invoice_status == 'invoiced' || row.invoice_status == 'to invoice') ? row.price_unit : 0) + '')
                                            //totale_da_fatturare = parser(totale_da_fatturare + '') + parser((row.invoice_status != 'invoiced' ? row.price_unit : 0) + '')
                                        return (
                                            <Table.Row>
                                                <Table.Cell> {/* Descrizione */}
                                                    {row.invoice_name}
                                                </Table.Cell> 
                                                <Table.Cell> {/* N° Fattura */}
                                                    <center>{row.label_invoice}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ordine Lobra */}
                                                    <center>{row.invoice_origin}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ordine Cliente */}
                                                    <center>{row.x_studio_customer_order_nr}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Data Fattura */}
                                                    <center>{moment(row.invoice_date).format('DD-MM-YYYY')}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Data Scadenza Fattura */}
                                                    <center>{moment(row.invoice_date_due).format('DD-MM-YYYY')}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Importo fatturato */}
                                                    <center>{(row.type_name == 'Credit Note' ? '-' : '')}{formatDecimal(row.price_subtotal, {
                                                            decimal: ',',
                                                            precision: 2,
                                                            thousands: '.'
                                                        }) + ' €'}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Data Next Invoice */}
                                                    <center>{moment(row.x_studio_next_invoicing_date).format('DD-MM-YYYY')}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Importo da fatturare */}
                                                    <center>{''}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Incassato */}
                                                    <center>{row.invoice_payment_state != 'paid'
                                                            ? (<CloseIcon
                                                                fontSize="small"
                                                                style={{
                                                                color: red[500]
                                                            }}/>)
                                                            : (<CheckIcon
                                                                fontSize="small"
                                                                style={{
                                                                color: green[500]
                                                            }}/>)}</center>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
    }
                                {schedacommessa
                                    .info_project
                                    .piano_contabile_dafatturare.filter((row) => { return row.price_subtotal != 0})
                                    .map((row) => {
                                        if(row.price_unit == null){
                                            row.price_unit = 0
                                        }
                                        totale_fatturato = parser(totale_fatturato + '') + parser((row.invoice_status == 'invoiced' ? row.price_unit : 0) + '')
                                        totale_da_fatturare = parser(totale_da_fatturare + '') + parser((row.invoice_status != 'invoiced' ? row.price_subtotal : 0) + '')
                                        return (
                                            <Table.Row>
                                                <Table.Cell> {/* Descrizione */}
                                                    {row.invoice_name}
                                                </Table.Cell>
                                                <Table.Cell> {/* N° Fattura */}
                                                    <center>{row.label_invoice}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ordine Lobra */}
                                                    <center>{row.label_sale_order_id}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Ordine Cliente */}
                                                    <center>{row.x_studio_customer_order_nr}</center>
                                                </Table.Cell>
                                                <Table.Cell>{/* Data Fattura */}
                                                    <center>{row.invoice_status == 'invoiced' ? (<center>{moment(row.date).format('DD-MM-YYYY')}</center>):(<div></div>)}</center>
                                                </Table.Cell>
                                                <Table.Cell>{/* Data Scadenza Fattura */}
                                                    <center>{row.invoice_status == 'invoiced' ? (<center>{moment(row.invoice_date_due).format('DD-MM-YYYY')}</center>):(<div></div>)}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Import Fatturato */}
                                                    {row.invoice_status == 'invoiced' ? (<center>{formatDecimal(row.price_unit, {
                                                            decimal: ',',
                                                            precision: 2,
                                                            thousands: '.'
                                                        }) + ' €'}</center>) : (<div></div>)}
                                                </Table.Cell>
                                                <Table.Cell> {/* Data Next Invoice */}
                                                    <center>{moment(row.x_studio_next_invoicing_date).format('DD-MM-YYYY')}</center>
                                                </Table.Cell>
                                                <Table.Cell> {/* Importo da Fatturare */}
                                                {row.invoice_status != 'invoiced' ? (<center>{formatDecimal(row.price_subtotal, {
                                                            decimal: ',',
                                                            precision: 2,
                                                            thousands: '.'
                                                        }) + ' €'}</center>) : (<div></div>)}
                                                </Table.Cell> 
                                                <Table.Cell> {/* Incassato */}
                                                    <center>{row.invoice_payment_state != 'paid'
                                                            ? (<CloseIcon
                                                                fontSize="small"
                                                                style={{
                                                                color: red[500]
                                                            }}/>)
                                                            : (<CheckIcon
                                                                fontSize="small"
                                                                style={{
                                                                color: green[500]
                                                            }}/>)}</center>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
    }

                            </Table.Body>

                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='6'>Totale Fatturato / Da Fatturare</Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'>
                                        <center>{formatDecimal(totale_fatturato, {
                                                decimal: ',',
                                                precision: 2,
                                                thousands: '.'
                                            }) + ' €'}</center>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'></Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'>
                                        <center>{formatDecimal(totale_da_fatturare, {
                                                decimal: ',',
                                                precision: 2,
                                                thousands: '.'
                                            }) + ' €'}</center>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='2'></Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='1'>Totale Contrattuale</Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile"colSpan='1'>
                                        <center>{formatDecimal(totale_piano_economico, {
                                                decimal: ',',
                                                precision: 2,
                                                thousands: '.'
                                            }) + ' €'}</center>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell id="footer_piano_contabile" colSpan='8'></Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>

                        </Table>

                    </div>
                )
            }
        } else {
            return (
                <div></div>
            )
        }
    } else {
        return (<div></div>)
    }
}

const SceltaProgetto = ({
    login,
    header,
    schedacommessa,
    OnlyActiveProjects,
    AllProjects,
    UpdateSelectedProject,
    SendProjectToBE,
    GetProjectsList,
    UpdateSelectedDateRange,
    GetAllProjects,
    SetSelectedFilter
}) => {

    if ((header.active_menu.scheda_commessa_user || header.active_menu.scheda_commessa_admin) && schedacommessa.is_project_ok === false) {
        return(
            <div><br></br><br></br>
                <RicercaProgetti login={login} header={header} schedacommessa={schedacommessa} SetSelectedFilter={SetSelectedFilter} GetAllProjects={GetAllProjects} OnlyActiveProjects={OnlyActiveProjects} AllProjects={AllProjects} UpdateSelectedProject={UpdateSelectedProject} SendProjectToBE={SendProjectToBE} GetProjectsList={GetProjectsList} UpdateSelectedDateRange={UpdateSelectedDateRange} />
            </div>
        )
    } else {
        return (
            <div></div >
        )
    }
    

}

const GenerateLastUpdate = ({login, schedacommessa, GenerateLastUpdatePM}) => {

    const [needBeRequestPM, setRequestedPM] = useState(true);

    if (needBeRequestPM) {
        GenerateLastUpdatePM(schedacommessa.selected_project.id, schedacommessa.selected_project.active)
        setRequestedPM(false)
    }

    if( schedacommessa.pm_last_update == null){
        return(
            <h3>{}</h3>
        )
    } else {
        try {
            return(
                <div id="last_update">
                    <strong><h5 id="ultimo_agg">Company: </h5></strong>
                    <h5 id="user_ultimo_agg">{schedacommessa.selected_project.company_id[1]}</h5>
                    <strong><h5 id="ultimo_agg">PM: </h5></strong>
                    <h5 id="user_ultimo_agg">{schedacommessa.selected_project.user_id[1]}</h5>
                    <strong><h5 id="ultimo_agg">Ultimo Aggiornamento: </h5></strong>
                    <h5 id="user_ultimo_agg">{schedacommessa.pm_last_update.lastupdate[0].x_studio_user_last_update + " - " + moment(schedacommessa.pm_last_update.lastupdate[0].write_date).format("DD-MM-YYYY HH:mm")}</h5>
                </div>
                )
        } catch (error) {
            return(
            <div></div>
            )
        }
      
    }

}

const GeneraterAddEmployee = ({login, schedacommessa, GetInfoEmpContracts, GetFollowersProject,AddEmpToSchedaCommessa}) => {

    const [empinfo,
        setEmpInfo] = useState('');
    const [needBeRequest,
        setRequested] = useState(true);
    const [NeedEmpChoosed,
        setNeedEmpChoosed] = useState(true);

    var options = []

    if (needBeRequest) {
        GetInfoEmpContracts()
        GetFollowersProject(schedacommessa.selected_project.id)
        setRequested(false)
    }

    schedacommessa.empscontracts_list.sort((a,b) => natural(a.nome_utente, b.nome_utente)).map((emp) => {
        if(parser(emp.x_studio_daily_rate) > 0) {
        var keyid = (emp.nome_utente + emp.name)
           .split(' ')
           .join('')
        options.push({key: keyid, value: keyid, text: emp.nome_utente, contratto: emp.display_name, costo: schedacommessa.selected_project.company_id[0] == JSON.parse(emp.company_id)[0] ? emp.x_studio_daily_rate : (emp.x_studio_external == "1" ? emp.x_studio_daily_rate : emp.x_studio_daily_rate_ic), info: emp})
        }
    })

    const GetSelectedEmployee = (e, data) => {
        var empdata = data
            .options
            .filter((opzione) => {
                return opzione.value == data.value
            })
        setEmpInfo(empdata[0])
        setNeedEmpChoosed(false)
    }

    const Add_EmpToSchedaCommessa = () => {
        var emptoadd = {
            consulente: empinfo.info.nome_utente,
            costo_profilo: formatDecimal(empinfo.costo, {
                decimal: ',',
                precision: 2,
                thousands: '.'
            }),
            costo_timesheet_mese: 0,
            costo_timesheet_previsto: 0,
            costo_timesheet_maturato: 0,
            costo_trasferta_previsto: 0,
            costo_trasferta_maturato: 0,
            employee_id: JSON.parse(empinfo.info.employee_id),
            giorni_timesheet_mese: 0,
            giorni_timesheet_previsto: 0,
            user_id: login.info_login.user_id,
            keyid: empinfo.value,
            profilo: empinfo.info.name,
        }
        AddEmpToSchedaCommessa(empinfo.value, emptoadd)
    }

    return (
        <div>
            <Dropdown
                id='lista_emp'
                placeholder='Seleziona Employee'
                fluid
                search
                selection
                closeOnChange
                options={options}
                onClick={() => {}}
                onChange={GetSelectedEmployee}/>

                <Button onClick={Add_EmpToSchedaCommessa} basic disabled={NeedEmpChoosed} id="bottone_addemp">AGGIUNGI EMPLOYEE</Button>
        </div>

    )
}

const GenerateDateProject = ({schedacommessa,SetNewStartDate,SetNewEndDate}) => {

    const [startdate, setstartdate] = useState(moment(schedacommessa.start_date).format('DD-MM-YYYY'))
    const [enddate, setenddate] = useState(moment(schedacommessa.end_date).format('DD-MM-YYYY'))
    const [can_sel_anystart, setcan_sel_anystart] = useState(false)
    const [can_sel_anyend, setcan_sel_anyend] = useState(false)

    if(startdate === 'Invalid date'){
        setstartdate('Data Obbligatoria')
        setcan_sel_anystart(true)
    }
    if(enddate === 'Invalid date'){
        setenddate('Data Obbligatoria')
        setcan_sel_anyend(true)
    }

    const HandleChangeStartDate = (event, {name, value}) => {
        var dateStart
        if (schedacommessa.minmaxdate_maturato.length === 1) {
            dateStart = moment(schedacommessa.minmaxdate_maturato[0]);
        }
        if (schedacommessa.minmaxdate_maturato.length > 1) {
            dateStart = moment(schedacommessa.minmaxdate_maturato[0]);
        }
        if(moment(value,'DD-MM-YYYY').isAfter(dateStart)){
            setstartdate('Scegliere data Inferiore')
        } else {
            setstartdate(value)
            SetNewStartDate(moment(value,'DD-MM-YYYY').format('YYYY-MM-DD'))
        } 
    }

    const HandleChangeEndDate = (event, {name, value}) => {
        var dateStart
        if (schedacommessa.minmaxdate_maturato.length === 1) {
            dateStart = moment(schedacommessa.minmaxdate_maturato[0]);
        }
        if (schedacommessa.minmaxdate_maturato.length > 1) {
            dateStart = moment(schedacommessa.minmaxdate_maturato[schedacommessa.minmaxdate_maturato.length -1]);
        }
        if(moment(value,'DD-MM-YYYY').isBefore(dateStart)){
            setenddate('Scegliere data Superiore')
        } else {
            setenddate(value)
            SetNewEndDate(moment(value,'DD-MM-YYYY').format('YYYY-MM-DD'))
        }    
    }

    return (
        <div  id="date_project">
            <Grid>
                <Grid.Row>
                <Grid.Column width={3}>
                <Header id="start_date" size='small'>Data Inizio</Header>
                </Grid.Column>
                    <Grid.Column width={5}>
          <DateInput
          id="size_start_date"
            name="startdate"
            placeholder="Data Inizio progetto"
            value={startdate}
            iconPosition="left"
            onChange={HandleChangeStartDate}
          />
          </Grid.Column>
          <Grid.Column width={3}>
                <Header id="end_date" size='small'>Data Fine</Header>
                </Grid.Column>
          <Grid.Column width={5}>
           <DateInput
           id="size_end_date"
            name="enddate"
            placeholder="Data Fine Progetto"
            value={enddate}
            iconPosition="left"
            onChange={HandleChangeEndDate}
          />
          </Grid.Column>
          </Grid.Row>
          </Grid>
        </div>
      );
}

const mapStateToProps = state => ({header: state.headerMenu, schedacommessa: state.schedaCommessa, login: state.loginPage})

const mapDispatchToProps = dispatch => ({
    UpdateGenInfo:(part_geninfo) => dispatch(UpdateGenInfo(part_geninfo)),
    CreateXLS_SchedaCommessa:(scheda_commessa) => dispatch(CreateXLS_SchedaCommessa(scheda_commessa)),
    OnlyActiveProjects: () => dispatch(OnlyActiveProjects()),
    XLSDownloaded: () => dispatch(XLSDownloaded()),
    AllProjects: () => dispatch(AllProjects()),
    AddEmpToSchedaCommessa: (keyid, costo) => dispatch(AddEmpToSchedaCommessa(keyid, costo)),
    GetInfoEmpContracts: () => dispatch(GetInfoEmpContracts()),
    UpdateSelectedProject: (project) => dispatch(UpdateSelectedProject(project)),
    UpdateSelectedDateRange: (date) => dispatch(UpdateSelectedDateRange(date)),
    ProjectReadyToRender: (minmaxdate_maturato, minmaxdate_prevsionato,colonne_maturato, colonne_previsionato, righe, previonsato_day, previsionato_expense) => dispatch(ProjectReadyToRender(minmaxdate_maturato, minmaxdate_prevsionato,colonne_maturato, colonne_previsionato, righe, previonsato_day, previsionato_expense)),
    SendProjectToBE: () => dispatch(SendProjectToBE()),
    GetProjectsList: (emp_id, allprj) => dispatch(GetProjectsList(emp_id, allprj)),
    GetProjectInfo: (project_id, isactive, date) => dispatch(GetProjectInfo(project_id, isactive, date)),
    ResetGetProjectInfo: (project_id, isactive) => dispatch(ResetGetProjectInfo(project_id, isactive)),
    UpdateAllPrevisionatoData: (idproject, start_date, end_date, delete_prev_info, update_prev_day, write_prev_day, update_prev_expense, write_prev_expense) => dispatch(UpdateAllPrevisionatoData(idproject, start_date, end_date, delete_prev_info, update_prev_day, write_prev_day, update_prev_expense, write_prev_expense)),
    AddDeletePrevisionato: (ids, keyids) => dispatch(AddDeletePrevisionato(ids, keyids)),
    SetNewEndDate: (date) => dispatch(SetNewEndDate(date)),
    SetNewStartDate: (date) => dispatch(SetNewStartDate(date)),
    HandleEditCells: (e) => dispatch(HandleEditCells(e)),
    GetFollowersProject: (prjid) => dispatch(GetFollowersProject(prjid)),
    AddFollowerProject: (prjid, userid) => dispatch(AddFollowerProject(prjid, userid)),
    RemoveFollowerProject: (prjid, userid) => dispatch(RemoveFollowerProject(prjid, userid)),
    GenerateLastUpdatePM: (project_id, isactive) => dispatch(GenerateLastUpdatePM(project_id, isactive)),
    GetAllProjects: () => dispatch(GetAllProjects()),
    SetSelectedFilter: (key, values) => dispatch(SetSelectedFilter(key, values)),
})

export default {
    SceltaProgetto : connect(mapStateToProps, mapDispatchToProps)(SceltaProgetto),
    SchedaCommessaTabella : connect(mapStateToProps, mapDispatchToProps)(SchedaCommessaTabella),
    PianoContabile : connect(mapStateToProps, mapDispatchToProps)(GeneratePianoContabile),
    PianoEconomico : connect(mapStateToProps, mapDispatchToProps)(GeneratePianoEconomico),
    LoadingSpinnger : connect(mapStateToProps, mapDispatchToProps)(LoadingDivSpinner),
    AddEmployee : connect(mapStateToProps, mapDispatchToProps)(GeneraterAddEmployee),
}
