
import odoo_api from '../../odooAPI/backend_api_wrapper'

export const TryDoLoginOdoo = (username, password) => ({
    type: 'TRYDO_LOGIN_ODOO',
    payload: odoo_api.try_login_odoo(username, password)
})

export const LogoutOdoo = () => ({
    type: 'LOGOUT_ODOO'
})