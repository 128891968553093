import React, {useState} from 'react'
import { Dropdown, Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {Image, Input, Grid, Message, Button} from 'semantic-ui-react';
import { TryDoLoginOdoo } from "../login_page/login_actions"
import { SetRightsSections } from "../header_menu/headermenu_actions"
import { SetUser, SetAdmin } from '../scheda_commessa/schedacommessa_actions'
 
const CreatePageLogin = ({login, TryDoLoginOdoo, SetRightsSections, SetUser, SetAdmin}) => {

  const [needPassRightsInfo, setneedPassRightsInfo] = useState(true);
  const [username_value, setusername_value] = useState('');
  const [password_value, setpassword_value] = useState('');

  function DoLogin(){
    TryDoLoginOdoo(username_value, password_value)
  }

  function EnterDoLogin(e){
    if (e.key === 'Enter') {
      TryDoLoginOdoo(username_value, password_value)
    }
  }

  function SetUsernameValue(e){
    setusername_value(e.target.value)
  }

  function SetPasswordValue(e){
    setpassword_value(e.target.value)
  }


  if(login.info_login.is_loggedin){

    if(needPassRightsInfo){
      setneedPassRightsInfo(false)
      SetRightsSections(login.info_login)
    }

    SetUser(login.info_login.user_id)
    if(login.info_login.x_studio_scheda_commessa == 'Admin'){
      SetAdmin()
    }

    return (<div></div>)
} else {

   return(
    <div id="login_riquadro">
      <>
      <center>
      <Image id='Logo' src='https://www.lobra.com/wp-content/uploads/2019/09/logo.svg' />
      </center>
      </>
     
        <>
        <div className='login_class'>
          <Input fluid id='email' placeholder='Email' onChange={SetUsernameValue} onKeyDown={EnterDoLogin}/>
        </div>
      </>
      <>
        <div className='login_class'>
          <Input fluid id='password' type='password' placeholder='Password' onChange={SetPasswordValue} onKeyDown={EnterDoLogin}/>
        </div>
      </>
      <>
        <center>
        <Button disabled={login.info_login.is_loggingin} onClick={DoLogin} id='bottone_login'>Login</Button>
        </center>
      </>
      
      <Message hidden={(!login.info_login.error || login.info_login.is_loggingin ? true : false)} id="santa_pazienza_errore" color='red'>Credenziali non valide!</Message>
      <Message hidden={!login.info_login.is_loggingin} id="santa_pazienza_errore" color='red'>Login In Corso...</Message> 
    </div>
);
}
}



const mapStateToProps = state => ({
    login: state.loginPage,
})

const mapDispatchToProps = dispatch => ({
    TryDoLoginOdoo: (username, password) => dispatch(TryDoLoginOdoo(username, password)),
    SetRightsSections: (info) => dispatch(SetRightsSections(info)),
    SetUser: (user) => dispatch(SetUser(user)),
    SetAdmin: () => dispatch(SetAdmin()),
 
})

export default {
    LoginPage: connect(mapStateToProps, mapDispatchToProps)(CreatePageLogin),
}




