import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.css'
import LoginView from './Redux/login_page/login_view'
import HeaderView from './Redux/header_menu/headermenu_view'
import SchedaCommessa from './Redux/scheda_commessa/schedacommessa_view'
import Schedulazione from './Redux/schedulazione/schedulazione_view'

function App() {
  return (
    <div className="App">
      <LoginView.LoginPage></LoginView.LoginPage>
      <HeaderView.TopMenu></HeaderView.TopMenu>
      <SchedaCommessa.LoadingSpinnger></SchedaCommessa.LoadingSpinnger>
      <SchedaCommessa.SceltaProgetto></SchedaCommessa.SceltaProgetto>
      <SchedaCommessa.SchedaCommessaTabella></SchedaCommessa.SchedaCommessaTabella>
      <SchedaCommessa.PianoEconomico></SchedaCommessa.PianoEconomico>
      <SchedaCommessa.PianoContabile></SchedaCommessa.PianoContabile>
      <Schedulazione.LoadingDivSpinner></Schedulazione.LoadingDivSpinner>
      <Schedulazione.MainPage></Schedulazione.MainPage>
    </div>
  );
}

export default App;
