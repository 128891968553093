import odoo_api from '../../odooAPI/backend_api_wrapper'

export const LogoutOdoo_Header = () => ({
    type: 'LOGOUT'
})

export const OpenSideBar = () => ({
    type: 'OPEN_SIDE_BAR'
})

export const CloseSideBar = () => ({
    type: 'CLOSE_SIDE_BAR'
})

export const Toogle_SchedaCommessaUser = () => ({
    type: 'SCHEDA_COMMESSA_USER'
})

export const Toogle_SchedaCommessaAdmin = () => ({
    type: 'SCHEDA_COMMESSA_ADMIN'
})

export const Toogle_Schedulazione = () => ({
    type: 'SCHEDULAZIONE'
})

export const Toogle_AccountingTools = () => ({
    type: 'ACCOUNTING_TOOLS'
})

export const Toogle_ReportisticaAdmin = () => ({
    type: 'REPORTISTICA_ADMIN'
})

export const SetRightsSections = (info) => {

    var obj_rights = {}

    // Scheda Commessa
    if(info.x_studio_scheda_commessa == 'Project Manager'){
        obj_rights = {...obj_rights,
            scheda_commessa_user: true,
            scheda_commessa_admin: false,
         }
    }
    if(info.x_studio_scheda_commessa == 'Admin'){
        obj_rights = {...obj_rights,
            scheda_commessa_user: false,
            scheda_commessa_admin: true,
         }
    }

    // Schedulazione
    if(info.x_studio_schedulazione == 'Admin'){
        obj_rights = {...obj_rights,
            schedulazione_admin: true,
         }
    }



    return ({
        type: 'SET_PASS_USER_RIGHTS',
        user_rights: obj_rights
    })  
}
