import {combineReducers} from 'redux'

import schedaCommessaReducer from '../scheda_commessa/schedacommessa_reducer'
import headMenuReducer from '../header_menu/headermenu_reducer'
import LoginPageReducer from '../login_page/login_reducer'
import schedulazioneReducer from '../schedulazione/schedulazione_reducer'


export default combineReducers({
    schedaCommessa: schedaCommessaReducer,
    schedulazione: schedulazioneReducer,
    headerMenu: headMenuReducer,
    loginPage: LoginPageReducer
})