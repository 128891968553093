import moment from 'moment'
import mergeAdvanced from "object-merge-advanced";





const INITIAL_STATE = {
    working_days: 0,
    loading: false,
    calldonebe: false,
    schedulazione_data: [],
    indexer: [],
    filters: {
        Filtri: {},
        PreFiltri: {}
    },
    emp_total: 0,
    emp_rossi: 0,
    emp_bianchi: 0,
    emp_verdi: 0,
    emp_gialli: 0,
    lavorativi: 0,
    elenco_note: {id: null},
    test: null
}

const currentReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'SAVE_LAVORATIVI_MESE':
            return {
                ...state,
                lavorativi: action.payload
            }
        case 'GETSCHEDULAZIONE_MESE_FULFILLED':

            var dataSource = []

            function getFiltri(settings){
                if(dataSource.length > 0){
                    
                        var FilterSettings = {
                            Filtri: {},
                            PreFiltri: {}
                        }
            
                        // Per ogni proprietà nel Settings ciclo
            
                        Object.entries(settings).map((key, value) => {
            
                            //Creo la proprietà nei Filtri e Prefiltri
                            FilterSettings.Filtri[key[0]] = []
                            FilterSettings.PreFiltri[key[0]] = []
            
                            //Ciclo la Ticket List e Appendo i valori per La Proprietà Selezionata
                            dataSource.map((Ticket) => {
                                if(Array.isArray(Ticket[key[0]])){
                                    Ticket[key[0]].map((value) => {
                                        if(FilterSettings.PreFiltri[key[0]].includes(value)){
                                        } else {
                                            FilterSettings.PreFiltri[key[0]].push(value)
                                            if(value != null){
                                                FilterSettings.Filtri[key[0]].push({ text: value, value: value })
                                            }
                                            
                                        }
                                    })
                                } else {
                                    if(FilterSettings.PreFiltri[key[0]].includes(Ticket[key[0]])){
                                    } else {
                                        FilterSettings.PreFiltri[key[0]].push(Ticket[key[0]])
                                        if(Ticket[key[0]] != null){
                                        FilterSettings.Filtri[key[0]].push({ text: Ticket[key[0]], value: Ticket[key[0]] })
                                        }
                                    }
                                }        
                            })

                            // Ordino i Filtri e Prefiltri
            
                            
                            
                            // Controllo se la Prorietà del Settings ha per Caso un valore da Escludere o Includere
                            if (settings[key[0]].hasOwnProperty('defiltra') || settings[key[0]].defiltra != null || settings[key[0]].defiltra != undefined) {
                                //settings[key[0]].defiltra
                                //myArray.filter( ( el ) => !toRemove.includes( el ) );
                                FilterSettings.PreFiltri[key[0]] = FilterSettings.PreFiltri[key[0]].filter( ( value ) => !settings[key[0]].defiltra.includes( value ) );
            
                            } else if (settings[key[0]].hasOwnProperty('prefiltra') || settings[key[0]].prefiltra != null || settings[key[0]].prefiltra != undefined) {
                                var TempPrefiltro = []
                                settings[key[0]].prefiltra.map((valore) => {
                                    if(FilterSettings.PreFiltri[key[0]].includes(valore)){
                                        TempPrefiltro.push(valore)
                                    }
                                })  
                                FilterSettings.PreFiltri[key[0]] = TempPrefiltro
                            } else {
                                FilterSettings.PreFiltri[key[0]] = []   
                            }
            
                            // Rendo in Disinct il Filtro e PreFiltro
                            FilterSettings.Filtri[key[0]] = [...new Set(FilterSettings.Filtri[key[0]])].sort((a,b) => a.value > b.value);
                            FilterSettings.PreFiltri[key[0]] = [...new Set(FilterSettings.PreFiltri[key[0]])].sort((a,b) => a.value > b.value);

                                   
                        })  
                        
                        /* // Li ordino internamente
                        FilterSettings.Filtri.map((filtro, index) => {
                            FilterSettings.Filtri[index] = filtro.sort((a,b) => a.updated_at - b.updated_at);
                        }) */


                        return FilterSettings



                } else {
                    var FilterSettings = {
                        Filtri: {},
                        PreFiltri: {}
                    }
                    return FilterSettings
                }
            }
            
            var elenco_note = {}

            Object.keys(action.payload).map(function(key, index) {
                /* action.payload[key] *= 2; */

                if(action.payload[key].previsionato == undefined || action.payload[key].previsionato.length == 0){
                    dataSource.push({
                        name: key,
                        note: action.payload[key].note,
                        previste: 0,    
                        team: action.payload[key].team,
                        progetto: null,
                        previsionato: 0,
                        cliente: null,
                        manager: null
                    })

                     // salvo un oggetto con le note
                     elenco_note[key] = null
                     elenco_note[key] = action.payload[key].note
                } else {
                    action.payload[key].previsionato.map((progetto) => {
                        dataSource.push({
                            name: key,
                            note: action.payload[key].note,
                            previste: action.payload[key].totale_giorni,
                            team: action.payload[key].team,
                            progetto: progetto.nome_progetto,
                            previsionato: progetto.x_studio_days,
                            cliente: progetto.cliente,
                            manager: progetto.project_manager
                        })
                    })

                    // salvo un oggetto con le note
                    elenco_note[key] = null
                    elenco_note[key] = action.payload[key].note
                }

                
                
            });

            var indexerTemp = []
                dataSource.map((riga,index,array) => {
                indexerTemp.push({index: index, rowspan: 1, value: riga.name})
            })

            var cur_ref_index = 0
            var indexer = [...indexerTemp]
            indexerTemp.map((line, index, array) => {
                if(index < indexerTemp.length - 1){
                    if(indexerTemp[cur_ref_index].value == indexerTemp[index + 1].value){
                    // sono uguale quindi metto rowspan + 1 al index di riferimento e 0 a questo index
                    indexer[cur_ref_index].rowspan += 1
                    indexer[index + 1].rowspan = 0
                    } else {
                    cur_ref_index = index + 1
                    }
                }
            })
            
            var filtri = getFiltri({note: {},name:{}, previste:{}, team: {}, progetto:{}, previsionato: {}, cliente:{}, manager: {}})
 
            return {
                ...state,
                loading: false,
                schedulazione_data: dataSource,
                indexer: indexer,
                filters: filtri,
                test: action.payload,
                calldonebe: true,
                elenco_note: elenco_note,
                emp_total: Object.keys(action.payload).length,
                emp_rossi: Object.entries(action.payload).filter((x) => x[1].totale_giorni < 13).length,
                emp_gialli: Object.entries(action.payload).filter((x) => x[1].totale_giorni > 13 && x[1].totale_giorni < state.lavorativi).length,
                emp_verdi: Object.entries(action.payload).filter((x) => x[1].totale_giorni === state.lavorativi).length,
                emp_bianchi: Object.entries(action.payload).filter((x) => x[1].totale_giorni > state.lavorativi).length,
            }

        case 'GETSCHEDULAZIONE_MESE_PENDING':
            return {
                ...state,
                loading: true,
                calldonebe: false,
                schedulazione_data: [],
                indexer: [],
                filters: {
                    Filtri: {},
                    PreFiltri: {}
                },
                emp_total: 0,
                emp_rossi: 0,
                emp_bianchi: 0,
                emp_verdi: 0,
                emp_gialli: 0,
                elenco_note: {}
            }
        case 'INSERT_NOTE_SCHED_MESE_FULFILLED':
            var temp_elenco_note = state.elenco_note
            temp_elenco_note[action.payload.empname].id = action.payload.newid
            temp_elenco_note[action.payload.empname].nota = action.payload.x_studio_note
            return {
                ...state
            }
        case 'UPDATE_NOTE_SCHED_MESE_FULFILLED':
            var temp_elenco_note = state.elenco_note
            temp_elenco_note[action.payload.empname].nota = action.payload.x_studio_note
            return {
                ...state
            }
        case 'GET_WORKING_DAYS_FULFILLED':
            return {
                ...state,
                working_days: action.payload,
                lavorativi: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default currentReducer