import React from 'react'
import { connect } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu';
import  {IconButton}  from '@material-ui/core';
import {Image, Input, Grid, Message, Button, Dropdown, Header, Checkbox, Menu, Segment, Sidebar, List} from 'semantic-ui-react';
import PersonIcon from '@material-ui/icons/Person';
import { LogoutOdoo } from "../login_page/login_actions"
import { LogoutOdoo_Header } from "../header_menu/headermenu_actions"
import { LogoutOdoo_SchedaCommessa } from "../scheda_commessa/schedacommessa_actions"
import { OpenSideBar, CloseSideBar, Toogle_SchedaCommessaUser, Toogle_SchedaCommessaAdmin, Toogle_Schedulazione, Toogle_AccountingTools, Toogle_ReportisticaAdmin } from "./headermenu_actions"
import { ResetSchedaCommessa, GetAllProjects } from '../scheda_commessa/schedacommessa_actions'




const CreateHeaderMenu = ({login, header, LogoutOdoo, LogoutOdoo_Header, LogoutOdoo_SchedaCommessa, OpenSideBar, CloseSideBar, Toogle_SchedaCommessaUser, Toogle_SchedaCommessaAdmin, Toogle_Schedulazione, Toogle_AccountingTools, Toogle_ReportisticaAdmin}) => {


    const GlobalLogout = () => {
        LogoutOdoo()
        LogoutOdoo_Header()
        LogoutOdoo_SchedaCommessa()
    }

    console.log(login.info_login.is_loggedin)
    if(login.info_login.is_loggedin){
        return (
            <Grid>
            <Grid.Row>
        <Grid.Column width={1}>
            <IconButton id='menu' color="secondary" aria-label="add an alarm" onClick={OpenSideBar} ><MenuIcon /></IconButton>
            {GenerateSidebar(header, CloseSideBar, Toogle_SchedaCommessaUser, Toogle_SchedaCommessaAdmin, Toogle_Schedulazione, Toogle_AccountingTools, Toogle_ReportisticaAdmin)}
        </Grid.Column>
        <Grid.Column width={4}>
        <Image id='Logo_2' src='https://www.lobra.com/wp-content/uploads/2019/09/logo.svg' />
        </Grid.Column>
        <Grid.Column width={7}>
        <> 
        <center>
        <Message id='Titolo' color='red' size='big'>{header.active_menu.label_topheader}</Message>
        </center>
        </>
        </Grid.Column>
        <Grid.Column width={4}>
       <Header>
       <PersonIcon id='icona' fontSize='large' />
       <Dropdown id='tendina' text={login.info_login.self[1]}>
           <Dropdown.Menu>
               <Dropdown.Item text='Sign Out' onClick={GlobalLogout}/>
            </Dropdown.Menu>
       </Dropdown>  
       </Header>     
        </Grid.Column>
        </Grid.Row>
        </Grid>
        )
    } else {
        return(<div></div>)
    }
}

const GenerateSidebar = (header, CloseSideBar, Toogle_SchedaCommessaUser, Toogle_SchedaCommessaAdmin, Toogle_Schedulazione, Toogle_AccountingTools, Toogle_ReportisticaAdmin) => {

    return (
        <Sidebar as={Segment} dimmed={true} animation={header.sidebar_prop.animation} direction={header.sidebar_prop.direction} onClick={CloseSideBar} visible={header.sidebar_prop.visible}>    
            <img class="ondine1" src="https://www.lobra.com/wp-content/themes/lobra/assets/ondina.svg" id="ondine"></img>
            <List link>
                {header.user_rights.scheda_commessa_user && <List.Item as='a' id='link_menu' onClick={Toogle_SchedaCommessaUser}>Scheda Commessa ( PM )</List.Item>}
                {header.user_rights.scheda_commessa_admin && <List.Item as='a' id='link_menu' onClick={Toogle_SchedaCommessaAdmin}>Scheda Commessa ( Admin )</List.Item>}
                {header.user_rights.schedulazione_admin && <List.Item as='a' id='link_menu' onClick={Toogle_Schedulazione}>Schedulazione</List.Item>}
                {header.user_rights.accounting_tools && <List.Item as='a' id='link_menu' onClick={Toogle_AccountingTools}>Accounting Tools</List.Item>} 
                {header.user_rights.reportistica_admin && <List.Item as='a' id='link_menu' onClick={Toogle_ReportisticaAdmin}>Reportistica KPI</List.Item>}           
            </List>
        </Sidebar>
    )
}

const mapStateToProps = state => ({
    login: state.loginPage,
    header: state.headerMenu
})

const mapDispatchToProps = dispatch => ({
    ResetSchedaCommessa: () => dispatch(ResetSchedaCommessa()),
    LogoutOdoo: () => dispatch(LogoutOdoo()),
    LogoutOdoo_Header: () => dispatch(LogoutOdoo_Header()),
    LogoutOdoo_SchedaCommessa: () => dispatch(LogoutOdoo_SchedaCommessa()),
    OpenSideBar: () => dispatch(OpenSideBar()),
    CloseSideBar: () => dispatch(CloseSideBar()),
    Toogle_SchedaCommessaUser: () => {
        dispatch(Toogle_SchedaCommessaUser())
        dispatch(ResetSchedaCommessa())
        dispatch(GetAllProjects())
    },
    Toogle_SchedaCommessaAdmin: () => {
        dispatch(Toogle_SchedaCommessaAdmin())
        dispatch(ResetSchedaCommessa())
        dispatch(GetAllProjects())
    },
    Toogle_AccountingTools: () => dispatch(Toogle_AccountingTools()),
    Toogle_Schedulazione: () => {
        dispatch(Toogle_Schedulazione())
        dispatch(ResetSchedaCommessa())
    },
    Toogle_ReportisticaAdmin: () => dispatch(Toogle_ReportisticaAdmin()),
})

export default {
    TopMenu: connect(mapStateToProps, mapDispatchToProps)(CreateHeaderMenu),
}




