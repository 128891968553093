
var request = require('request-promise');

/* var encrypter = require('object-encrypter');
var engine = encrypter('A63IHD7DR7EW82JS89838U4JJ43IS99288HD7HD7DR7EW82JS8983DR7EW82JS89834U4JJ3FSODHFOEAJFJDSAKFJDSALKJFSAJFOIEWJOIFSAOFDLSAJDOSAJDLKSAJO86598598582357DFGJL843UJ2NJSAIDSSHD7DR7EW82JS8983HD7DR7EW82JS898383993934I2JN22929JHD7DR7EW82JHD7DR7EW82JS8983S8983SJDSAISA9IJ', {
    ttl: true
});
 */

//const serverprod = "http://localhost:2222"
//const serverprod = "https://lobra-staging.azurewebsites.net"
const serverprod = "https://accounting.lobra.com"

const odoo_api = {

    try_login_odoo: function (username, password) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/login_odoo',
                qs: {
                    username: username,
                    password: password
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };

            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_projects_list_admin: function (status) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/getprojectsadmin',
                qs: {
                    status: status
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };

            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },
    

    get_projects_list_pm: function (emp_id, status) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/getprojectspm',
                qs: {
                    employee_id: emp_id,
                    status: status
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    generate_schedacommessa_export: function (geninfo) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/genxlsxschedacommessa',
                body: {
                    geninfo: geninfo
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
            });

        })
    },

    get_all_project_info_maturato: function (project_id, isactive, date) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/getallinfoproject',
                qs: {
                    project_id: project_id,
                    status: isactive,
                    date: date
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    updateprevisionato: function (id, values) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'PUT',
                uri:  serverprod + '/odoo/previsionato',
                body: {
                    id: id,
                    values: values
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    insertprevisionato: function (values) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/previsionato',
                body: {
                    values: values
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    getlistempcontracts: function (values) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/getempcontracts',
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    deleteprevisionato: function (id) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'DELETE',
                uri:  serverprod + '/odoo/previsionato',
                body: {
                    id: id
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    updatestartdateproject: function (id, date) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'PUT',
                uri:  serverprod + '/odoo/startdateproject',
                body: {
                    id: id,
                    date: date
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    updateenddateproject: function (id, date) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'PUT',
                uri:  serverprod + '/odoo/enddateproject',
                body: {
                    id: id,
                    date: date
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    get_pm_last_update: function (project_id, isactive) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/getpmlastupdate',
                qs: {
                    project_id: project_id,
                    status: isactive
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },


    get_schedulazione: function (date) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/info_schedulazione',
                qs: {
                    date: date
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    get_working_days: function (date) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/working_days',
                qs: {
                    date: date
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    updatenoteschedulazione: function (id, note, empname) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'PUT',
                uri:  serverprod + '/odoo/update_note_schedulazione',
                body: {
                    id: id,
                    note: note,
                    empname: empname
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },


    insertnoteschedulazione: function (empid, date, note, empname) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/insert_note_schedulazione',
                body: {
                    empid: empid,
                    date: date,
                    note: note,
                    empname: empname
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    create_xls_schedacommessa: function (json_xls) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'POST',
                uri:  serverprod + '/odoo/create_xls_schedacommessa',
                body: {
                    json: json_xls
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
            });
        })
    },

    getfollowersproject: function (prjid) {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/followers',
                qs: {
                    prjid: prjid,
                },
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },

    addfollowerproject: function (prjid, userid) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'PUT',
                uri:  serverprod + '/odoo/followers',
                body: {
                    prjid: prjid,
                    userid: userid
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    removefollowerproject: function (prjid, userid) {
        return new Promise(function (resolve, reject) {
            var options = {
                method: 'DELETE',
                uri:  serverprod + '/odoo/followers',
                body: {
                    prjid: prjid,
                    userid: userid
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                    resolve(apiresp)
                })
                .catch(function (apierror) {
                    reject(apierror)
                });

        })
    },

    getallprojects: function () {
        return new Promise(function (resolve, reject) {
            var options = {
                uri:  serverprod + '/odoo/allprjs',
                headers: {
                    'User-Agent': 'Request-Promise'
                },
                json: true // Automatically parses the JSON string in the response
            };
            request(options).then(function (apiresp) {
                resolve(apiresp)
            })
            .catch(function (apierror) {
               reject(apierror)
            });

        })
    },



    
}

export default odoo_api