import React, { useContext, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import { GetWorkingDays, GetSchedulazione_Mese, SaveLavorativiMeseAttuale, SaveSingleNote } from './schedulazione_actions'
import {Image, Grid, Message, Dropdown, Menu, Label, TableHeaderCell} from 'semantic-ui-react';
import  {Table, Tooltip, Input, Button, Popconfirm, FormButton, Tag, Space, Form, Col, Row, Checkbox} from 'antd';
import {LeftOutlined, RightOutlined, SendOutlined, InboxOutlined , PaperClipOutlined, HourglassOutlined, SyncOutlined, PlusOutlined, SearchOutlined, UploadOutlined, ArrowLeftOutlined, UserOutlined, LoadingOutlined, SmileOutlined, CheckCircleTwoTone} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import gstore from '../global/global_store'
import moment from 'moment'

import '../antd.css';


// TABELLA ANT DESIGN

var natural = require("natural-compare")

const dataSource = [];

const EditableContext = React.createContext();

const SaveNote = (e) => {
  var noteinfo = JSON.parse(e.target.attributes.noteinfo.value)
  if(e.target.value.trim() != "" || noteinfo.id > 0){  
    gstore.dispatch(SaveSingleNote(e.target.value, noteinfo, gstore.getState().schedulazione.elenco_note))
  }
}

class TabellaSchedulazione extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        width: '10%', 
        editable: true,
        render: (value, row, index) => {
          const obj = {
            children: <div></div>,
            props: {},
          };
          
          obj.props.rowSpan = (this.state.rowSpanner[index] != undefined) ? this.state.rowSpanner[index].rowspan : this.props.rowSpanner[index].rowspan


          obj.children = <Form><TextArea id={'text_area_schedulazione'} key={(gstore.getState().schedulazione.elenco_note[row.name] == undefined ? '' : gstore.getState().schedulazione.elenco_note[row.name].nota.split('"').join('').split('<p>').join('').split('</p>').join('').split('\\n').join(String.fromCharCode(13)))} autosize={true} noteinfo={JSON.stringify(value)} defaultValue={(gstore.getState().schedulazione.elenco_note[row.name] == undefined ? '' : gstore.getState().schedulazione.elenco_note[row.name].nota.split('"').join('').split('<p>').join('').split('</p>').join('').split('\\n').join(String.fromCharCode(13)))} onBlur={SaveNote}></TextArea></Form>

          return obj;
        },
        //sortOrder: sortedInfo.columnKey === 'note' && sortedInfo.order
      },
      {
        title: 'Nome e Cognome',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };


          obj.props.rowSpan = (this.state.rowSpanner[index] != undefined) ? this.state.rowSpanner[index].rowspan : this.props.rowSpanner[index].rowspan
          
          return obj;
        },
        filters: this.props.filters.Filtri.name.sort((a,b) => natural(a.value, b.value))|| null,
        onFilter: (value, record) => (record.name != undefined ? record.name.indexOf(value) === 0 : null),
        sorter: (a,b) => natural(a.name, b.name),
        defaultSortOrder: 'ascend'
        //sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      {
          title: 'Totale Giornate',
          dataIndex: 'previste',
          key: 'previste',
          width: '5%',
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {},
            };
  
            obj.props.rowSpan = (this.state.rowSpanner[index] != undefined) ? this.state.rowSpanner[index].rowspan : this.props.rowSpanner[index].rowspan

            if(value < 13){
              obj.children = <div class='emp_rosso'>{value}</div>
            }
            if(value >= 13 && value < this.props.lavorativi){
              obj.children = <div class='emp_giallo'>{value}</div>
            }
            if(value === this.props.lavorativi){
              obj.children = <div class='emp_verde'>{value}</div>
            }
            if(value > this.props.lavorativi){
              obj.children = <div class='emp_bianco'>{value}</div>
            }
            
            return obj;
          },
          filters: this.props.filters.Filtri.previste.sort((a,b) => natural(a.value, b.value)) || null,
          onFilter: (value, record) => (record.previste != undefined ? record.previste == value : null),
          sorter: (a,b) => natural(a.previste, b.previste),
          //sortOrder: sortedInfo.columnKey === 'previste' && sortedInfo.order
        },
      {
        title: 'Progetto',
        dataIndex: 'progetto',
        key: 'progetto',
        width: '18%',
        filters: this.props.filters.Filtri.progetto.sort((a,b) => natural(a.value, b.value)) || null,
        onFilter: (value, record) => (record.progetto != undefined ? record.progetto.indexOf(value) === 0 : null),
        //sortOrder: sortedInfo.columnKey === 'progetto' && sortedInfo.order
      },
      {
        title: 'Giornate Previste',
        dataIndex: 'previsionato',
        key: 'previsionato',
        width: '5%',
        filters: this.props.filters.Filtri.previsionato.sort((a,b) => natural(a.value, b.value)) || null,
        render: (value, row, index) => {
          const obj = {
            children: <center>{value}</center>,
            props: {},
          };

          return obj;
        },
        onFilter: (value, record) => (record.previsionato != undefined ? record.previsionato == value : null),
        //sortOrder: sortedInfo.columnKey === 'previsionato' && sortedInfo.order
      },
      {
        title: 'Cliente',
        dataIndex: 'cliente',
        key: 'cliente',
        width: '10%',
        filters: this.props.filters.Filtri.cliente.sort((a,b) => natural(a.value, b.value)) || null,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };

          return obj;
        },
        onFilter: (value, record) => (record.cliente != undefined ? record.cliente.indexOf(value) === 0 : null),
        //sortOrder: sortedInfo.columnKey === 'cliente' && sortedInfo.order
      },
      {
        title: 'Project Manager',
        dataIndex: 'manager',
        key: 'manager',
        width: '10%',
        filters: this.props.filters.Filtri.manager.sort((a,b) => natural(a.value, b.value)) || null,
        render: (value, row, index) => {
          const obj = {
            children:value,
            props: {},
          };

          return obj;
        },
        onFilter: (value, record) => (record.manager != undefined ? record.manager.indexOf(value) === 0 : null),
        sorter: (a,b) => natural(a.manager, b.manager),
        //sortOrder: sortedInfo.columnKey === 'manager' && sortedInfo.order
      },
      {
        title: 'Team Manager',
        dataIndex: 'team',
        key: 'team',
        width: '7%',
        filters: this.props.filters.Filtri.team.sort((a,b) => natural(a.value, b.value)) || null,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };



          obj.props.rowSpan = (this.state.rowSpanner[index] != undefined) ? this.state.rowSpanner[index].rowspan : this.props.rowSpanner[index].rowspan
          
          return obj;
        },
        onFilter: (value, record) => (record.team != undefined ? record.team.indexOf(value) === 0 : null),
        sorter: (a,b) => natural(a.team, b.team),
      },
    ];


    this.state = {
      sortedInfo: null,
      rowSpanner: this.props.rowSpanner,
      dataSource: this.props.sorgente, 
      filters: this.props.filters
    };

    this.state.rowSpanner = this.props.rowSpanner
    this.state.filters = this.props.filters
    this.state.lavorativi = this.props.lavorativi
    this.state.elenco_note = this.props.elenco_note

  }

  // Filtri Dinamici!


  handleSpanIndexer = currentDataSource => {
    var indexerTemp = []
    currentDataSource.map((riga,index,array) => {
      indexerTemp.push({index: index, rowspan: 1, value: riga.name})
    })

    var cur_ref_index = 0
    var indexer = [...indexerTemp]
    indexerTemp.map((line, index, array) => {
      if(index < indexerTemp.length - 1){
        if(indexerTemp[cur_ref_index].value == indexerTemp[index + 1].value){
          // sono uguale quindi metto rowspan + 1 al index di riferimento e 0 a questo index
          indexer[cur_ref_index].rowspan += 1
          indexer[index + 1].rowspan = 0
        } else {
          cur_ref_index = index + 1
        }
      }
    })
 
    this.state.rowSpanner = indexer
    return indexer

  }

  handleDelete = key => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter(item => item.key !== key),
    });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: 32,
      address: `London, Park Lane no. ${count}`,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
  };

  handleChange = (pagination, filters, sorter, info) => {
    this.handleSpanIndexer(info.currentDataSource)
    this.setState({
      sortedInfo: sorter,
    });
  };

  render() {
    const { dataSource, sortedInfo, rowSpanner, filters } = this.state;

    const columns = this.columns.map(col => {return col; });
    return (
      <div>
        <Table
          onChange={this.handleChange}
          rowClassName={() => 'editable-row'}
          rowSpanner={this.props.rowSpanner}
          filters={this.props.filters}
          dataSource={this.props.sorgente}
          lavorativi={this.props.lavorativi}
          elenco_note={this.props.elenco_note}
          columns={columns}
          pagination={{ pageSize: 999 }}
          size="middle"
        />
      </div>
    );
  }
}

// FINE TABELLA ANT DESIGN

const LoadingDivSpinner = ({schedulazione}) => {
  if (schedulazione.loading) {
      return (
          <center>
                  
              <div id="div_spinnger">
                  <CircularProgress id="spinner_color_lobra" size="6rem"/>
                  <h1>Caricamento Schedulazione in Corso...</h1>
              </div>
          </center>
      )
          
  } else {
      return (
        <div></div>
      )
          
  }
}
 
const MainPage = ({header, schedulazione, GetSchedulazione_Mese, SaveLavorativiMeseAttuale, SaveSingleNote, GetWorkingDays}) => {


  const [needRequestCurrentMonth, SetneedRequestCurrentMonth] = useState(true);
  const [SelectedMonth, SetSelectedMonth] = useState(moment());
  const [GiorniLavorativi, SetGiorniLavorativi] = useState(schedulazione.working_days)
  
  function add_month(){
    SetSelectedMonth(SelectedMonth.add(1, 'months'))
    getgiornilavorativi()
    SetneedRequestCurrentMonth(true)
  }

  function remove_month(){
    SetSelectedMonth(SelectedMonth.subtract(1, 'months'))
    getgiornilavorativi()
    SetneedRequestCurrentMonth(true)
  }



  function getgiornilavorativi(){
    var g_lavorativi = 0

    function getAllDatesOfMonth(date) {
      const mDate = moment(date, "YYYY-MM");
      const daysCount = mDate.daysInMonth();
      return Array(daysCount).fill(null).map((v,index)=>{
          const addDays = index === 0 ? 0 : 1;
          return mDate.add(addDays, 'days').format('YYYY-MM-DD');
      });
    }
  
    getAllDatesOfMonth(SelectedMonth).map((giorno) => {
        if(moment(giorno).format('dddd') == 'sabato' || moment(giorno).format('dddd') == 'domenica'){
        } else {
            g_lavorativi = g_lavorativi + 1
        }
    })


    if(schedulazione.working_days > 0){
      SetGiorniLavorativi(schedulazione.working_days)
      SaveLavorativiMeseAttuale(schedulazione.working_days)
    } else {
      SetGiorniLavorativi(g_lavorativi)
      SaveLavorativiMeseAttuale(g_lavorativi)
    }

    return g_lavorativi
  }



    if(needRequestCurrentMonth && header.active_menu.schedulazione_admin){
      SetneedRequestCurrentMonth(false)
      GetWorkingDays(SelectedMonth.format('YYYY-MM-01'))     
      GetSchedulazione_Mese(SelectedMonth.format('YYYY-MM-01'))
      getgiornilavorativi()
    }

    if(needRequestCurrentMonth === false && header.active_menu.schedulazione_admin === false){
      SetneedRequestCurrentMonth(true)
    }

    if(header.active_menu.schedulazione_admin && schedulazione.loading == false){
        return (

          <div>
            <br></br><br></br>
              <Row>
              <Col flex="1%"></Col>
              <Col flex="46%" align="center">
              <Table pagination={{
                  hideOnSinglePage: true
              }} columns={[{title: 'Giorni Lavorativi',
                dataIndex: 'giorni_lavorativi',
                key: 'giorni_lavorativi',
                colSpan: 2,
                render: (value) => {
                    return(
                      <div>
                        <>
                          <center>
                            <Row align='center'>
                              <Col flex="1%"><LeftOutlined onClick={remove_month}></LeftOutlined></Col>
                              <Col flex="300px" align="center">{'  ' + SelectedMonth.format('MMMM').charAt(0).toUpperCase() + SelectedMonth.format('MMMM').slice(1) + '  ' + SelectedMonth.format('YYYY') }</Col>
                              <Col flex="1%"><RightOutlined onClick={add_month}></RightOutlined></Col>
                            </Row>
                          </center>
                        </>          
                      </div>
                    )
                }
              },
              {
                dataIndex: 'giorni_lavorativi',
                key: 'giorni_lavorativi',
                colSpan: 0,
                render: (value) => {
                    return(
                      <div>
                        <>
                          <center>
                             <h5>{schedulazione.lavorativi}</h5>
                          </center>
                        </>   
                      </div>
                    )
                }}
              ]} dataSource={[{}]} size="small"/>
              </Col>
              <Col flex="6%"></Col>
              <Col flex="46%" align="center">
              <Table pagination={{
                  hideOnSinglePage: true
              }} columns={[
              {
                title: 'Riepilogo Employee',
                dataIndex: 'rosso',
                key: 'rosso',
                colSpan: 4,
                render: (value) => {
                    return(<><center>{/* <Checkbox onClick={TabellaSchedulazione.funzioni_filtri} defaultChecked={false}/> */}
                      &nbsp; 
                      <Tooltip placement="bottom" color={'#a6a6a6bf'} title={'Employees con giornate inferiori a 13'}>
                      <Tag color="red">{value[0] + ' / ' + value[1]}</Tag>
                      </Tooltip>
                      </center></>)
                }
              },
              {
                dataIndex: 'bianco',
                key: 'bianco',
                colSpan: 0,
                render: (value) => {
                  return(<><center>{/* <Checkbox onClick={TabellaSchedulazione.funzioni_filtri} defaultChecked={false}/> */}
                    &nbsp; 
                    <Tooltip placement="bottom" color={'#a6a6a6bf'} title={'Employees con giornate superiori ai giorni lavorativi mensile'}>
                    <Tag >{value[0] + ' / ' + value[1]}</Tag>
                    </Tooltip>
                    </center></>)
              }
              },
              {
                dataIndex: 'verde',
                key: 'verde',
                colSpan: 0,
                render: (value) => {
                  return(<><center>{/* <Checkbox onClick={TabellaSchedulazione.funzioni_filtri} defaultChecked={false}/> */}
                    &nbsp; 
                    <Tooltip placement="bottom" color={'#a6a6a6bf'} title={'Employees con giornate uguali ai giorni lavorativi mensile'}>
                    <Tag color="green">{value[0] + ' / ' + value[1]}</Tag>
                    </Tooltip>
                    </center></>)
              }
              },
              {
                key: 'giallo',
                dataIndex: 'giallo',
                colSpan: 0,
                render: (value) => {
                  return(<><center>{/* <Checkbox onClick={TabellaSchedulazione.funzioni_filtri} defaultChecked={false}/> */}
                    &nbsp; 
                    <Tooltip placement="bottom" color={'#a6a6a6bf'} title={'Employees con giornate comprese tra 13 e giorni lavorativi mensile'}>
                    <Tag color="orange">{value[0] + ' / ' + value[1]}</Tag>
                    </Tooltip>
                    </center></>)
              }
                      
              },
            ]} dataSource={[
              {
                rosso: [schedulazione.emp_rossi,schedulazione.emp_total],
                bianco: [schedulazione.emp_bianchi,schedulazione.emp_total],
                verde: [schedulazione.emp_verdi,schedulazione.emp_total],
                giallo: [schedulazione.emp_gialli,schedulazione.emp_total],
              },
            ]} size="small"/>
              </Col>
              <Col flex="1%"></Col>
              </Row>

    <br></br>
    <br></br>
    <br></br>

    <Row>
        <Col flex="1%"></Col>
        <Col flex="98%">
        {(schedulazione.calldonebe ? <TabellaSchedulazione elenco_note={schedulazione.elenco_note} lavorativi={schedulazione.lavorativi} sorgente={schedulazione.schedulazione_data} rowSpanner={schedulazione.indexer} filters={schedulazione.filters}/> : <div></div>)}
        </Col>
        <Col flex="1%"></Col>
    </Row>

    </div>

        )      
    } else {
        return(
            <div></div>
        )
    }
}





const mapStateToProps = state => ({
  header: state.headerMenu, 
  login: state.loginPage,
  schedulazione: state.schedulazione})

const mapDispatchToProps = dispatch => ({
  GetSchedulazione_Mese: (date) => dispatch(GetSchedulazione_Mese(date)),
  GetWorkingDays: (date) => dispatch(GetWorkingDays(date)),
  SaveLavorativiMeseAttuale: (giorni) => dispatch(SaveLavorativiMeseAttuale(giorni)),
  SaveSingleNote: (msg, noteinfo, notelist) => dispatch(SaveSingleNote(msg, noteinfo, notelist))  
})

export default {
    LoadingDivSpinner: connect(mapStateToProps, mapDispatchToProps)(LoadingDivSpinner),
    MainPage: connect(mapStateToProps, mapDispatchToProps)(MainPage),
}




