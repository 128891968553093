import moment from 'moment'
import mergeAdvanced from "object-merge-advanced";

const INITIAL_STATE = {
    //stato iniziale dell'oggetto
    sidebar_prop: {
        visible: false,
        animation: 'push',
        direction: 'left',
    },

    user_rights: {
        scheda_commessa_user: false,
        scheda_commessa_admin: false,
        schedulazione_admin: false,
        accounting_tools: false,
        reportistica_admin: false,
    },

    active_menu: {
        label_topheader: 'Selezionare una voce dal Menù',
        scheda_commessa_user: false,
        scheda_commessa_admin: false,
        schedulazione_admin: false   ,
        accounting_tools: false,
        reportistica_admin: false,
    },

    loading: false
    
}

const currentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'LOGOUT':
            var editstate = {...INITIAL_STATE}
            delete editstate.user_rights
            return {
                ...state,
                ...editstate
            }

        case 'SET_PASS_USER_RIGHTS':
            var editstate = {...state}
            editstate.user_rights = {...editstate.user_rights, ...action.user_rights}
            return {
                ...editstate
            }
        case 'OPEN_SIDE_BAR':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: true
                }
            }
        case 'CLOSE_SIDE_BAR':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                }
            }
        case 'SCHEDA_COMMESSA_USER':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Scheda Commessa - (Project Manager)',
                    scheda_commessa_user: true,
                    scheda_commessa_admin: false,
                    schedulazione_admin: false,
                    accounting_tools: false,
                    reportistica_admin: false,
                }
            }
        case 'SCHEDA_COMMESSA_ADMIN':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Scheda Commessa - (Administrator)',
                    scheda_commessa_user: false,
                    scheda_commessa_admin: true,
                    schedulazione_admin: false,
                    accounting_tools: false,
                    reportistica_admin: false,
                }
            }
        case 'SCHEDULAZIONE':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Schedulazione',
                    scheda_commessa_user: false,
                    scheda_commessa_admin: false,
                    schedulazione_admin: true,
                    accounting_tools: false,
                    reportistica_admin: false,
                }
            }
        case 'ACCOUNTING_TOOLS':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Accounting Tools',
                    scheda_commessa_user: false,
                    scheda_commessa_admin: false,
                    schedulazione_admin: false,
                    accounting_tools: true,
                    reportistica_admin: false,
                }
            }
        case 'REPORTISTICA_ADMIN':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Reportistica',
                    scheda_commessa_user: false,
                    scheda_commessa_admin: false,
                    schedulazione_admin: false,
                    accounting_tools: false,
                    reportistica_admin: true,
                }
            }
        default:
            return state
    }
}

export default currentReducer