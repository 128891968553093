import odoo_api from '../../odooAPI/backend_api_wrapper'
import promise from 'promise'

export const GetSchedulazione_Mese = (date) => ({
    type: 'GETSCHEDULAZIONE_MESE',
    payload: odoo_api.get_schedulazione(date)
})

export const GetWorkingDays = (date) => ({
    type: 'GET_WORKING_DAYS',
    payload: odoo_api.get_working_days(date)
})

export const SaveLavorativiMeseAttuale = (giorni) => ({
    type: 'SAVE_LAVORATIVI_MESE',
    payload: giorni
})

export const SaveSingleNote = (msg, noteinfo, notelist) => {
    console.log(msg, noteinfo, notelist)
    var test = ''

    if(notelist[noteinfo.empname].id > 0){
        // update
        return {
            type: 'UPDATE_NOTE_SCHED_MESE',
            payload: odoo_api.updatenoteschedulazione(noteinfo.id, msg.split('"').join(''), noteinfo.empname)
        }
    } else {
        // insert
        return {
            type: 'INSERT_NOTE_SCHED_MESE',
            payload: odoo_api.insertnoteschedulazione(noteinfo.empid, noteinfo.date, msg.split('"').join(''), noteinfo.empname)
        }
    }
   
}

